var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "title is-7" }, [
            _vm._v("Add Location In " + _vm._s(_vm.Region.name)),
          ]),
          _c("div", { staticClass: "is-size-7 has-text-danger" }, [
            _vm._v(" " + _vm._s(_vm.msg) + " "),
          ]),
          _c("div", {
            staticClass:
              "is-flex is-flex-direction-row is-align-items-baseline",
          }),
          _c("div", { staticClass: "notification is-white" }, [
            _c(
              "div",
              { staticClass: "columns", staticStyle: { width: "100%" } },
              [
                _c("div", { staticClass: "column is-half" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("Location Name"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.DistributionZoneName,
                        expression: "DistributionZoneName",
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text" },
                    domProps: { value: _vm.DistributionZoneName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.DistributionZoneName = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "column is-half" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("Search Address"),
                  ]),
                  _c("input", {
                    ref: "autocomplete",
                    staticClass: "input is-small",
                    attrs: { type: "text", placeholder: "Enter a location" },
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "help is-size-7 is-warning m-0 p-0",
                      staticStyle: { height: "20px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.err_message) + " ")]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "columns", staticStyle: { width: "100%" } },
              [
                _c("div", { staticClass: "column is-one-fifth" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("Door Number"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.DoorNumber,
                        expression: "DoorNumber",
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text" },
                    domProps: { value: _vm.DoorNumber },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.DoorNumber = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "column is-one-fifth" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("Apartment"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.Apartment,
                        expression: "Apartment",
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text" },
                    domProps: { value: _vm.Apartment },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.Apartment = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "column is-one-fifth" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("Street"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.Street,
                        expression: "Street",
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text" },
                    domProps: { value: _vm.Street },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.Street = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "column is-one-fifth" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("City"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.City,
                        expression: "City",
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text" },
                    domProps: { value: _vm.City },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.City = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "column is-one-fifth" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("Postal Code"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.PostalCode,
                        expression: "PostalCode",
                      },
                    ],
                    staticClass: "input is-small",
                    attrs: { type: "text", placeholder: "T4A0X3" },
                    domProps: { value: _vm.PostalCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.PostalCode = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "columns", staticStyle: { width: "100%" } },
              [
                _c("div", { staticClass: "column is-one-quarter" }, [
                  _c(
                    "label",
                    { staticClass: "label is-small is-one-quarter" },
                    [_vm._v("Type")]
                  ),
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "select is-small is-fluid" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.DistributionZoneType,
                              expression: "DistributionZoneType",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.DistributionZoneType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _vm._l(
                            _vm.store.getters.DistributionZoneTypes,
                            function (type) {
                              return [
                                _c(
                                  "option",
                                  {
                                    key: type.value,
                                    domProps: { value: type },
                                  },
                                  [_vm._v(_vm._s(type.label))]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "column is-one-quarter" }, [
                  _c("label", { staticClass: "label is-small" }, [
                    _vm._v("Facility"),
                  ]),
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "select is-small" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Facility,
                              expression: "Facility",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.Facility = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _vm._l(_vm.store.getters.Facility, function (type) {
                            return [
                              _c(
                                "option",
                                { key: type, domProps: { value: type } },
                                [_vm._v(_vm._s(type))]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _vm.DistributionZoneType.label === "STORE"
                  ? _c("div", { staticClass: "column is-one-half" }, [
                      _c("label", { staticClass: "label is-small" }, [
                        _vm._v("Client"),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "div",
                          {
                            staticClass: "select is-small is-fluid",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.Vendor,
                                    expression: "Vendor",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.Vendor = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(
                                _vm.store.state.vendors,
                                function (vendor) {
                                  return _c(
                                    "option",
                                    {
                                      key: vendor.Id,
                                      staticClass: "is-size-7",
                                      domProps: {
                                        selected: _vm.Vendor === vendor.Id,
                                        value: vendor.Id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(vendor.businessUnit) +
                                          "***" +
                                          _vm._s(vendor.subBusinessUnit) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-one-quarter" }, [
                _c("label", { staticClass: "label is-small" }, [
                  _vm._v("Phone"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.PhoneNumber,
                      expression: "PhoneNumber",
                    },
                  ],
                  staticClass: "input is-small",
                  attrs: { type: "tel", placeholder: "xxxxxxxxxx" },
                  domProps: { value: _vm.PhoneNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.PhoneNumber = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "label is-small" }, [
                  _vm._v("Coordinates (Lat/Lng) "),
                  _c("span", [
                    _c(
                      "button",
                      {
                        staticClass: "button is-small is-primary p-1 ml-1",
                        class: { "is-loading": _vm.geocoding_loading },
                        staticStyle: { height: "20px" },
                        on: {
                          click: function ($event) {
                            return _vm.getCoordinates(
                              _vm.Street,
                              _vm.City,
                              _vm.PostalCode,
                              _vm.Province
                            )
                          },
                        },
                      },
                      [_vm._v(" Get coordinates ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column px-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.Latitude,
                          expression: "Latitude",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "input is-small",
                      attrs: { type: "number", placeholder: "Latitude" },
                      domProps: { value: _vm.Latitude },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.Latitude = _vm._n($event.target.value)
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "column pl-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.Longitude,
                          expression: "Longitude",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "input is-small",
                      attrs: { type: "number", placeholder: "Longitude" },
                      domProps: { value: _vm.Longitude },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.Longitude = _vm._n($event.target.value)
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-one-quarter" }, [
                _c("label", { staticClass: "label is-small" }, [
                  _vm._v("Size (Sq. Ft.)"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.Size,
                      expression: "Size",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "input is-small",
                  attrs: { type: "number", placeholder: "Size" },
                  domProps: { value: _vm.Size },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.Size = _vm._n($event.target.value)
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "column is-one-quarter" }, [
                _c("label", { staticClass: "label is-small" }, [
                  _vm._v("Number Of Employees"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.NumberOfEmployees,
                      expression: "NumberOfEmployees",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "input is-small",
                  attrs: { type: "number", placeholder: "Number Of Employees" },
                  domProps: { value: _vm.NumberOfEmployees },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.NumberOfEmployees = _vm._n($event.target.value)
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
            _vm.DistributionZoneType.label !== "STORE"
              ? _c(
                  "div",
                  { staticClass: "is-white is-flex is-flex-wrap-wrap" },
                  [
                    _c("label", { staticClass: "tag m-2 checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.IsFinalMile,
                            expression: "IsFinalMile",
                          },
                        ],
                        staticClass: "is-small",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.IsFinalMile)
                            ? _vm._i(_vm.IsFinalMile, null) > -1
                            : _vm.IsFinalMile,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.IsFinalMile,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.IsFinalMile = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.IsFinalMile = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.IsFinalMile = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" Is Final Mile "),
                    ]),
                    _c("label", { staticClass: "tag m-2 checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.IsForReverse,
                            expression: "IsForReverse",
                          },
                        ],
                        staticClass: "is-small",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.IsForReverse)
                            ? _vm._i(_vm.IsForReverse, null) > -1
                            : _vm.IsForReverse,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.IsForReverse,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.IsForReverse = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.IsForReverse = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.IsForReverse = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" Used For Reverse "),
                    ]),
                    _c("label", { staticClass: "tag m-2 checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.AllowCrossdock,
                            expression: "AllowCrossdock",
                          },
                        ],
                        staticClass: "is-small",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.AllowCrossdock)
                            ? _vm._i(_vm.AllowCrossdock, null) > -1
                            : _vm.AllowCrossdock,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.AllowCrossdock,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.AllowCrossdock = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.AllowCrossdock = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.AllowCrossdock = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" Crossdock "),
                    ]),
                    _c("label", { staticClass: "tag m-2 checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.AllowOvernightReception,
                            expression: "AllowOvernightReception",
                          },
                        ],
                        staticClass: "is-small",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.AllowOvernightReception)
                            ? _vm._i(_vm.AllowOvernightReception, null) > -1
                            : _vm.AllowOvernightReception,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.AllowOvernightReception,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.AllowOvernightReception = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.AllowOvernightReception = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.AllowOvernightReception = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" Overnight Reception "),
                    ]),
                    _c("label", { staticClass: "tag m-2 checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.shouldConsiderFuelSurcharge,
                            expression: "shouldConsiderFuelSurcharge",
                          },
                        ],
                        staticClass: "is-small",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.shouldConsiderFuelSurcharge
                          )
                            ? _vm._i(_vm.shouldConsiderFuelSurcharge, null) > -1
                            : _vm.shouldConsiderFuelSurcharge,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.shouldConsiderFuelSurcharge,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.shouldConsiderFuelSurcharge = $$a.concat(
                                    [$$v]
                                  ))
                              } else {
                                $$i > -1 &&
                                  (_vm.shouldConsiderFuelSurcharge = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.shouldConsiderFuelSurcharge = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" Include In Fuel Surcharge Calculation "),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "my-3" }, [_vm._v("Data Source")]),
            _c("label", { staticClass: "tag m-2 checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.IsCDSRegion,
                    expression: "IsCDSRegion",
                  },
                ],
                staticClass: "is-small",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.IsCDSRegion)
                    ? _vm._i(_vm.IsCDSRegion, null) > -1
                    : _vm.IsCDSRegion,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.IsCDSRegion,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.IsCDSRegion = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.IsCDSRegion = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.IsCDSRegion = $$c
                    }
                  },
                },
              }),
              _vm._v(" Shyftbase "),
            ]),
            _c("label", { staticClass: "tag m-2 checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.IsCustomerRegion,
                    expression: "IsCustomerRegion",
                  },
                ],
                staticClass: "is-small",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.IsCustomerRegion)
                    ? _vm._i(_vm.IsCustomerRegion, null) > -1
                    : _vm.IsCustomerRegion,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.IsCustomerRegion,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.IsCustomerRegion = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.IsCustomerRegion = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.IsCustomerRegion = $$c
                    }
                  },
                },
              }),
              _vm._v(" External "),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "notification is-white has-text-centered is-fullwidth",
            },
            [
              _c(
                "button",
                {
                  staticClass: "button is-small is-success",
                  on: {
                    click: () => {
                      _vm.saveToFirestore()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ]
          ),
        ])
      : _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "flex-direction": "column",
              padding: "0",
              margin: "0",
            },
          },
          [_c("PulseLoader")],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }