<template>
  <div>
    <div class="" v-if="!loading">
      <md-list class="md-double-line">
        <div class="title is-5">Edit Shape</div>
        <div class="is-size-6 has-text-weight-semibold has-text-danger">
          {{ msg }}
        </div>
        <div
          class="
            is-flex
            is-flex-direction-column
            is-flex-wrap-wrap
            is-align-items-baseline
          "
        >
          <div
            class="
              is-size-6 is-flex is-flex-direction-row
              has-text-weight-semibold
            "
          >
            Zone: {{ shape.zone }}
          </div>
          <div
            class="
              is-size-6 is-flex is-flex-direction-row
              has-text-weight-semibold
            "
          >
            Region:
          </div>
          <div class="is-size-6">{{ shape.region }}</div>
        </div>
        <div class="columns notification is-white">
          <div class="column has-text-centered">
            <button class="button is-info is-small" @click="cancelEdit()">
              Cancel
            </button>
          </div>
          <div class="column has-text-centered" v-if="editable">
            <button class="button is-small is-primary" @click="editFirestore">
              Save
            </button>
          </div>
        </div>
      </md-list>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
      "
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import EventHandler from "../EventHandler";

const SINGLE_SHAPE = "SINGLE_SHAPE";
const SINGLE_SHAPE_WITH_HOLES = "SINGLE_SHAPE_WITH_HOLES";
const MULTI_SINGLE_SHAPE = "MULTI_SINGLE_SHAPE";
const MULTI_SINGLE_WITH_HOLES = "MULTI_SINGLE_WITH_HOLES";

export default {
  components: {
    PulseLoader,
  },
  data() {
    return {
      msg: "",
      loading: false,
    };
  },
  props: {
    shapesToAdd: {
      type: [Array, Object],
    },
    shape: {
      type: Object,
    },
    shapeType: {
      type: String,
    },
    editable: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      EventHandler.$emit("closeModal");
      this.close();
      this.editNameMode = false;
    },
    cancelEdit() {
      this.cancelEdit();
    },
    async saveCoordinates(shapesToAdd, shapeId, shapeType) {
      if (shapeType === SINGLE_SHAPE) {
        const coordinates = shapesToAdd.coordinates[0];
        const fsa = shapesToAdd.fsa;
        try {
          await this.updateZone({
            coordinates,
            fsa,
            shapeId,
            multi: false,
          });
          await this.removeShape();
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.msg = "Error in editing zone";
        }
      } else if (shapeType === SINGLE_SHAPE_WITH_HOLES) {
        const coordinates = shapesToAdd.coordinates;
        const fsa = shapesToAdd.fsa;
        try {
          await this.updateZone({
            coordinates,
            fsa,
            shapeId,
            multi: true,
          });
          await this.removeShape();
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.msg = "Error in editing zone";
        }
      } else if (shapeType === MULTI_SINGLE_SHAPE) {
        const selectedShape = this.shape;
        this.updateMultipleZone({
          newShapes: shapesToAdd,
          currentShape: selectedShape,
          shapeType: MULTI_SINGLE_SHAPE,
        });
        await this.removeShape();
      } else {
        const selectedShape = this.shape;
        this.updateMultipleZone({
          newShapes: shapesToAdd,
          currentShape: selectedShape,
          shapeType: MULTI_SINGLE_WITH_HOLES,
        });
        await this.removeShape();
      }
    },
    async editFirestore() {
      this.activateEditOnProcess();
      this.loading = true;
      const shapeType = this.shapeType.trim();
      const shapeId = this.shape.id.trim();
      const shapesToAdd = this.shapesToAdd;
      this.saveCoordinates(shapesToAdd, shapeId, shapeType);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
  background: #e93434;
  color: #ffffff;
}
</style>
