var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "title is-7" }, [
            _vm._v(
              " Saving Shape " +
                _vm._s(
                  _vm.currentRegion ? `In Region ${_vm.currentRegion}` : ""
                ) +
                " "
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "is-size-7 has-text-weight-semibold has-text-danger",
            },
            [_vm._v(" " + _vm._s(_vm.msg) + " ")]
          ),
          _c("div", {
            staticClass:
              "is-flex is-flex-direction-row is-align-items-baseline",
          }),
          _c(
            "div",
            { staticClass: "notification is-white" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.zoneList,
                  label: "value",
                  "close-on-select": true,
                  "track-by": "value",
                },
                model: {
                  value: _vm.zone,
                  callback: function ($$v) {
                    _vm.zone = $$v
                  },
                  expression: "zone",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "columns notification is-white" }, [
            _c("div", { staticClass: "column" }),
            _c("div", { staticClass: "column has-text-centered" }, [
              _c(
                "button",
                {
                  staticClass: "button is-warning is-small",
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "button",
                {
                  staticClass: "button is-small is-success",
                  on: {
                    click: () => {
                      _vm.saveToFirestore()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ]),
            _c("div", { staticClass: "column" }),
          ]),
        ])
      : _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "flex-direction": "column",
              padding: "0",
              margin: "0",
            },
          },
          [_c("PulseLoader")],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }