<template>
  <section style="max-width: 500px; margin: 0 auto" class="section is-small">
    <h3 class="title is-5">Chose your environment</h3>
    <button class="button is-fullwidth is-success my-1" @click="changePath('Logistics')">
      Logistics
    </button>
    <button
      class="button is-fullwidth is-success my-1"
      @click="changePath('Installation')"
    >
      Installations
    </button>
    <button
      class="button is-fullwidth is-success my-1"
      @click="changePath('Services')"
    >
      Services
    </button>
    <button class="button is-fullwidth is-yellow my-1" @click="logout">
      Logout
    </button>
  </section>
</template>

<script>
import { auth } from "@/store/fireStore";
export default {
  name: "Environments",
  data() {
    return {
      client: process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID,
    };
  },
  methods: {
    changePath(_path) {
      this.$router.push(`/views/${_path}?id=${this.$route.query.id}`);
    },
    logout() {
      this.$store.dispatch("resetValues");
      localStorage.removeItem("user");
      localStorage.removeItem("access");
      auth.signOut();
      this.$router.push("/");
    },
  },
};
</script>
