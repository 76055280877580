import * as Realm from "realm-web";
import { ObjectId } from "bson";
import axios from "axios";
import { zonesInfoCollection } from "../store/fireStore";
import { Shape, Zone, Payload } from "../typings/interface";
import store from "../store";
import { CHANGE_ZONE_ID } from "../exports/historyConstants";
const mappingToolRealmAppIdForShape = process.env.VUE_APP_REALM_APP_SHAPE;
const mappingToolRealmAppIdForPostalCode =
  process.env.VUE_APP_REALM_APP_POSTALCODE;
const mappingToolRealmAppIdForFSA = process.env.VUE_APP_REALM_APP_FSA;
const mappingToolRealmAppIdForHistory = process.env.VUE_APP_REALM_APP_HISTORY;
const realmAppForShape = new Realm.App({ id: mappingToolRealmAppIdForShape });
const realmAppForPostalCode = new Realm.App({
  id: mappingToolRealmAppIdForPostalCode,
});
const realmAppForFSA = new Realm.App({ id: mappingToolRealmAppIdForFSA });
const realmAppForSnapshots = new Realm.App({
  id: mappingToolRealmAppIdForHistory,
});
const userEmailRealm = process.env.VUE_APP_REALM_APP_USER;
const userPassRealm = process.env.VUE_APP_REALM_APP_PASS;
const installationEnv = process.env.VUE_APP_INSTALL_DB_ENV;
const logisticEnv = process.env.VUE_APP_LOGISTICS_DB_ENV;
const serviceEnv = process.env.VUE_APP_SERVICE_DB_ENV;
const mieleProfessionalsEnv = process.env.VUE_APP_PROFESSIONAL_DB_ENV;
const credentials = Realm.Credentials.emailPassword(
  userEmailRealm,
  userPassRealm
);
const [
  mappingDbAppForShape,
  mappingDbAppForPostalCode,
  mappingDbAppForFSA,
  mappingDbAppForSnapshots,
] = [
  realmAppForShape,
  realmAppForPostalCode,
  realmAppForFSA,
  realmAppForSnapshots,
].map(async (realmApp) => {
  return await realmApp
    .logIn(credentials)
    .then(async (user) => {
      return user?.mongoClient("mongodb-atlas");
    })
    .then((mongoDb) => {
      return mongoDb;
    });
});

const getDbName = (environment: string) => {
  let dbName = "";
  switch (environment) {
    case "installation":
      dbName = installationEnv;
      break;
    case "logistics":
      dbName = logisticEnv;
      break;
    case "services":
      dbName = serviceEnv;
      break;
    case "mieleprofessionals":
      dbName = mieleProfessionalsEnv;
      break;
    default:
      dbName = logisticEnv;
      break;
  }
  return dbName;
};
export const mongoDbAppForShapeManual = async (environment: any) => {
  const _environment = environment.toLowerCase();
  const mongodb = await mappingDbAppForShape;
  return mongodb.db(getDbName(_environment));
};

export const mongoDbAppForShape = async () => {
  const environment = store.state.environment.toLowerCase();
  const mongodb = await mappingDbAppForShape;
  return mongodb.db(getDbName(environment));
};
export const mongoDbAppForPostalCode = async () => {
  // const environment = store.state.environment
  const mongodb = await mappingDbAppForPostalCode;
  return mongodb.db("logistics");
};
export const mongoDbAppForHistory = async () => {
  const environment = store.state.environment;
  const mongodb = await mappingDbAppForSnapshots;
  return mongodb.db(getDbName(environment));
};
export async function updatePostalCode(
  coordinates: any,
  zoneId: string,
  isMulti: boolean
) {
  const environment = store.state.environment.toLowerCase();
  const dbName = getDbName(environment);

  const client: string = process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID;
  console.log("Update PostalCode: ", client, dbName);
  try {
    return await axios
      .post(process.env.VUE_APP_UPDATE_POSTAL_CODES, {
        coordinates: coordinates,
        isMulti: isMulti,
        newZone: zoneId,
        dbName,
        client,
      })
      .then(() => {
        return true;
      });
  } catch (error) {
    return false;
  }
}
export async function getHistories() {
  const region = store.state.region.trim();
  const mongoAppForHistory = await mongoDbAppForHistory();
  const snapshotsCollection = mongoAppForHistory.collection("snapshots");
  const snapshots = await snapshotsCollection.find({
    region: region,
  });
  const sortedSnapshot: any = snapshots.sort((a, b) => {
    return (new Date(b.date) as any) - (new Date(a.date) as any);
  });
  return sortedSnapshot;
}
export async function addHistory(action: string, payload: Payload) {
  const region = store.state.region.trim();
  const mongoAppForHistory = await mongoDbAppForHistory();
  const snapshotsCollection = mongoAppForHistory.collection("snapshots");
  const snapshot = {
    action,
    payload,
    date: new Date(),
    region: region,
  };
  console.log("AddHistory > snapshot", snapshot);
  return await snapshotsCollection.insertOne(snapshot);
}
export async function deleteHistory(id: string) {
  const mongoAppForHistory = await mongoDbAppForHistory();
  const snapshotsCollection = mongoAppForHistory.collection("snapshots");
  const oId = new ObjectId(id);
  await snapshotsCollection.deleteOne({
    _id: oId,
  });
}
/**
 * @returns zones - All shapes with its coordinates
 */
export async function getZones(region: any) {
  try {
    const currentRegion = store.state.region;
    // console.log('currentRegion', currentRegion)
    const zoneSelectedDoc = await zonesInfoCollection.get();
    // console.log('region:', region)
    // const zoneSelected = zoneSelectedDoc.docs.map((doc) => doc.id.trim())
    const mongoApp = await mongoDbAppForShape();
    const zonesDataCollection = await mongoApp.collection("zones").find({
      region: region,
    });
    const shapes = zonesDataCollection.map((zone) => ({
      ...zone,
      _id: zone._id.toString(),
      shapeId: zone._id.toString(),
    }));
    // const zonesDoc = await zonesInfoCollection.get()
    const zones = zoneSelectedDoc.docs.map((doc) => ({
      shapesID: doc.id.trim(),
      zoneId: doc.id.trim(),
      ...doc.data(),
    }));
    const shapeZones = shapes.map((shp: Shape) => {
      const shapeZoneInfo: Zone | undefined = zones.find(
        (z) => z.shapesID === shp.shapesID
      );
      const shapeZone = {
        ...shapeZoneInfo,
        ...shp,
        zone: shapeZoneInfo?.value,
      };
      return shapeZone;
    });
    return shapeZones;
  } catch (e) {
    console.error("er:", e);
    throw e;
  }
}
/**
 * @param  {[any]} coordinates - coordinates of a shape, format of coordinates are different if its whole or has a hole
 * @param  {string} shapesID - shapesID is the zone ID to assign to a shape
 * @param  {boolean} multi - information if the shape has whole or not
 * @param  {any} fsa? - usually an array of FSA id that assigned to that shape
 */
export async function insertZone(
  coordinates: [any],
  shapesID: string,
  multi: boolean,
  fsa?: [string],
  calledUndo?: boolean,
  oldId?: string,
  calledMulti?: boolean
) {
  try {
    const mongoAppForShape = await mongoDbAppForShape();
    // const mongodbForShapes: any = await mappingDbAppForShape
    const zonesCollection = mongoAppForShape.collection("zones");
    const currentRegion = store.state.region;
    const zonesData = !calledUndo
      ? {
          coordinates: JSON.stringify(coordinates),
          shapesID,
          multi,
          fsa: fsa || [],
          region: currentRegion,
          // circleProperties: circleProperties || null
        }
      : {
          region: currentRegion,
          coordinates: JSON.stringify(coordinates),
          shapesID,
          multi,
          fsa: fsa || [],
          _id: new ObjectId(oldId),
          // circleProperties: circleProperties || null
        };
    // console.log('oldId:', oldId)
    const { insertedId } = await zonesCollection.insertOne(zonesData);
    // const environment = store.state.environment
    // const oId = new ObjectId(insertedId)
    // const payloadData = { ...zonesData, zoneShapeId: oId.toString() }
    // if (!calledUndo && !calledMulti) {
    //   await addHistory(INSERT_ZONE, payloadData)
    // }
    // const updatedPostal = await updatePostalCode(coordinates, shapesID, multi)
    // console.log('updatedPostal:', updatedPostal)
    return {
      coordinates,
      shapesID,
      multi,
      insertedId,
    };
  } catch (e) {
    console.log("E:", e);
    return false;
  }
}
/**
 * @param  {string} coordinates New coordinates of the shape
 * @param  {string} shapeId The selected shape mongodb ID to edit
 * @param  {boolean} multi if shape has hole or not
 * @param  {any} fsa? new FSA assigned to it
 */
export async function editZoneCoordinates(data: {
  coordinates: string;
  shapeId: string;
  multi: boolean;
  fsa?: [string];
}) {
  try {
    const shapeId = data.shapeId;
    const newCoordinates = data.coordinates;
    const newMulti = data.multi;
    const fsa = data.fsa || [];
    const mongoAppForShape = await mongoDbAppForShape();
    const zonesCollection = mongoAppForShape.collection("zones");
    const oId = new ObjectId(shapeId);
    return zonesCollection
      .findOne({
        _id: oId,
      })
      .then(async (currentSelectedZone) => {
        const {
          shapesID,
          coordinates: currentZoneCoordinates,
          multi: currentZoneMulti,
        } = currentSelectedZone;
        const parseCurrentZoneCoordinates = JSON.parse(currentZoneCoordinates);
        const environment = store.state.environment;
        const dbName: string = getDbName(environment);
        console.log(`this ${process.env.VUE_APP_UPDATE_POSTAL_CODES}`);
        const oId = new ObjectId(shapeId);
        const editedShape = await zonesCollection.updateOne(
          {
            _id: oId,
          },
          {
            $set: {
              coordinates: JSON.stringify(newCoordinates),
              multi: newMulti,
              fsa: fsa,
            },
          }
        );
        return {
          currentZoneCoordinates: parseCurrentZoneCoordinates,
          currentZoneMulti,
          newZoneCoordinates: newCoordinates,
          newMulti,
          newShapesId: shapesID,
          dbName,
          shapeId,
          editedShape,
        };
      });
  } catch (e) {
    return false;
  }
}
/**
 * @param  {string} shapeId - Selected Shape ID to be deleted
 */
export async function deleteZone(shapeId: string, calledUndo = false) {
  try {
    const mongoAppForShape = await mongoDbAppForShape();
    const zonesCollection = mongoAppForShape.collection("zones");
    const oId = new ObjectId(shapeId);
    const currentSelectedZone = await zonesCollection.findOne({
      _id: oId,
    });
    const {
      coordinates: currentZoneCoordinates,
      multi: currentZoneMulti,
      // fsa: currentFSA,
      // shapesID
    } = currentSelectedZone;
    const parseCurrentZoneCoordinates = JSON.parse(currentZoneCoordinates);
    const deletedZone = await zonesCollection.deleteOne({
      _id: oId,
    });
    return {
      coordinates: parseCurrentZoneCoordinates,
      isMulti: currentZoneMulti,
      newZone: "",
      deletedZone,
    };
    // const payloadData = {
    //   coordinates: currentZoneCoordinates,
    //   multi: currentZoneMulti,
    //   fsa: currentFSA,
    //   shapesID,
    //   zoneShapeId: oId.toString()
    // }
    // if (!calledUndo) {
    //   await addHistory(DELETE_ZONE, payloadData)
    // }
    // return true
  } catch (e) {
    return false;
  }
}
/**
 * @param  {shapeId?:string} shapeId - Selected Shape mongodb ID to edit FSA
 * @param  {any} fsa? - Array of New FSA id of selected Shape
 */
export async function editZoneFSA(data: { shapeId: string; fsa: string[] }) {
  try {
    const shapeId = data.shapeId;
    const fsa = data.fsa;
    const mongoAppForShape = await mongoDbAppForShape();
    const zonesCollection = mongoAppForShape.collection("zones");
    const oId = new ObjectId(shapeId);
    return zonesCollection.updateOne(
      {
        _id: oId,
      },
      {
        $set: {
          fsa: fsa,
        },
      }
    );
  } catch (e) {
    return false;
  }
}
export async function EditShapeZone(data: {
  coordinates: string;
  shapeId: string;
  multi: boolean;
  fsa: [string];
  calledUndo?: boolean;
}) {
  const shapeId = data.shapeId;
  console.log("shapeId:", typeof shapeId);
  const newCoordinates = data.coordinates;
  const newMulti = data.multi;
  const newFsa = data.fsa;
  // const calledUndo = data.calledUndo
  const oId = new ObjectId(shapeId);
  console.log("oId:", oId);
  // const mongoAppForShape = await mongoDbAppForShape()
  // const zonesCollection = mongoAppForShape.collection('zones')
  // const {
  //   coordinates: currentZoneCoordinates,
  //   multi: currentZoneMulti,
  //   fsa: currentFsa
  // } = await zonesCollection.findOne({
  //   _id: oId
  // })
  // const payloadData = {
  //   zoneShapeId: oId.toString(),
  //   current: {
  //     coordinates: currentZoneCoordinates,
  //     multi: currentZoneMulti,
  //     fsa: currentFsa,
  //   },
  //   new: {
  //     multi: newMulti,
  //     coordinates: JSON.stringify(newCoordinates),
  //     newFsa
  //   }
  // }
  // if (!calledUndo) {
  //   await addHistory(EDIT_COORDINATES_ZONE, payloadData)
  // }
  return await editZoneCoordinates({
    coordinates: newCoordinates,
    shapeId,
    multi: newMulti,
    fsa: newFsa,
  });
  // await editZoneFSA({
  //   shapeId,
  //   fsa: newFsa
  // })
}
export async function editZoneCoordinatesMultiple(
  newShapes: any,
  currentShape: any,
  shapeType: string,
  calledUndo = false
) {
  const zoneId = currentShape.zoneId || "";
  let newInsertedId = [];
  console.log("currentShape:", currentShape);
  const mongoAppForShape = await mongoDbAppForShape();
  const zonesCollection = mongoAppForShape.collection("zones");
  const oId = new ObjectId(currentShape.id);
  const currentSelectedZone = await zonesCollection.findOne({
    _id: oId,
  });
  let deletedData: any = null;
  const {
    coordinates: currentZoneCoordinates,
    multi: currentZoneMulti,
    fsa: currentFSA,
    shapesID,
  } = currentSelectedZone;
  if (shapeType === "MULTI_SINGLE_SHAPE") {
    deletedData = (await deleteZone(currentShape.id)) as any;
    const { coordinates: coordinatesDeleted, isMulti: isMultiDeleted } =
      deletedData;
    newInsertedId = await updatePostalCode(
      coordinatesDeleted,
      "",
      isMultiDeleted
    ).then(async () => {
      return await Promise.all(
        newShapes.map(async (shape: any) => {
          const coordinates = shape.coordinates;
          const fsa = shape.fsa;
          return insertZone(
            coordinates,
            zoneId,
            false,
            fsa,
            false,
            "",
            true
          ).then(async () => {
            await updatePostalCode(coordinates, zoneId, false);
          });
        })
      );
    });
  } else {
    deletedData = (await deleteZone(currentShape.id)) as any;
    const { coordinates: coordinatesDeleted, isMulti: isMultiDeleted } =
      deletedData;
    newInsertedId = await updatePostalCode(
      coordinatesDeleted,
      "",
      isMultiDeleted
    ).then(async () => {
      return await Promise.all(
        newShapes.map(async (shape: any) => {
          const coordinates = shape.coordinates;
          const fsa = shape.fsa;
          if (coordinates.length > 1) {
            return insertZone(
              coordinates,
              zoneId,
              true,
              fsa,
              false,
              "",
              true
            ).then(async () => {
              await updatePostalCode(coordinates, zoneId, true);
            });
          } else {
            return insertZone(
              coordinates[0],
              zoneId,
              false,
              fsa,
              false,
              "",
              true
            ).then(async () => {
              await updatePostalCode(coordinates[0], zoneId, false);
            });
          }
        })
      );
    });
  }
  // const payloadData = {
  //   newInsertedId,
  //   currentShape: {
  //     coordinates: currentZoneCoordinates,
  //     multi: currentZoneMulti,
  //     fsa: currentFSA,
  //     shapesID,
  //     zoneShapeId: oId.toString()
  //   },
  //   shapeType
  // }
  // if (!calledUndo) {
  //   await addHistory(EDIT_COORDINATES_ZONE_MULTIPLE, payloadData)
  // }
  return { newInsertedId, deletedData };
}
/**
 * @param  {string} newShapesID - New Zone Id to be assign to the shape
 * @param  {string} shapeId - Selected Shape mongodb ID
 */
export async function changeZoneId(
  newShapesID: string,
  shapeId: string,
  calledUndo = false
) {
  try {
    const mongoAppForShape = await mongoDbAppForShape();
    const zonesCollection = mongoAppForShape.collection("zones");
    const oId = new ObjectId(shapeId);
    const { shapesID: currentShapesId } = await zonesCollection.findOne({
      _id: oId,
    });
    const payloadData = {
      shapesID: currentShapesId,
      zoneShapeId: oId.toString(),
    };
    if (!calledUndo) {
      await addHistory(CHANGE_ZONE_ID, payloadData);
    }
    return zonesCollection.updateMany(
      {
        _id: oId,
      },
      {
        $set: {
          shapesID: newShapesID,
        },
      }
    );
  } catch (e) {
    return false;
  }
}
/**
 * @param  {string[]} shapeId - Array of zones ID to be selected
 */
export async function getSelectedZone(shapeId: string) {
  try {
    const mongoAppForShape = await mongoDbAppForShape();
    const zonesDataCollection = await mongoAppForShape
      .collection("zones")
      .find({
        shapesID: { $in: [shapeId] },
      });
    const shapes = zonesDataCollection.map((zone: Zone) => ({
      ...zone,
      _id: zone._id?.toString(),
      shapeId: zone._id?.toString(),
    }));
    const zonesDoc = await zonesInfoCollection.get();
    const zones = zonesDoc.docs.map((doc) => ({
      shapesID: doc.id.trim(),
      zoneId: doc.id.trim(),
      ...doc.data(),
    }));
    const shapeZones = shapes.map((shp: Shape) => {
      const shapeZoneInfo = zones.find(
        (z: Zone) => z.shapesID === shp.shapesID
      );
      const shapeZone = { ...shapeZoneInfo, ...shp };
      return shapeZone;
    });
    return shapeZones;
  } catch (e) {
    return false;
  }
}
export async function getFSA(region: string) {
  try {
    const mongoAppForFSA = await mappingDbAppForFSA;

    let fsa_region = "";

    switch (region) {
      case "BC":
        fsa_region = "British_Columbia";
        break;
      case "AB":
        fsa_region = "Alberta";
        break;
      case "SK":
        fsa_region = "Saskatchewan";
        break;
      case "MB":
        fsa_region = "Manitoba";
        break;
      case "ON":
        fsa_region = "Ontario";
        break;
      case "QC":
        fsa_region = "Quebec";
        break;
      case "NB":
        fsa_region = "New_Brunswick";
        break;
      case "NS":
        fsa_region = "Nova_Scotia";
        break;
      case "PE":
        fsa_region = "Prince_Edward_Island";
        break;
      case "NL":
        fsa_region = "Newfoundland_and_Labrador";
        break;
      case "YT":
        fsa_region = "Yukon";
        break;
      case "NT":
        fsa_region = "Northwest_Territories";
        break;
      case "NU":
        fsa_region = "Nunavut";
        break;
      default:
        fsa_region = "Ontario";
        break;
    }

    const FSA = await mongoAppForFSA
      .db("FSA")
      .collection(fsa_region || region)
      .find({});
    return FSA;
  } catch (e) {
    return [];
  }
}
export async function getAllPostalCode() {
  const db = await mongoDbAppForPostalCode();
  const locations = await db.collection("postal_codes").find();
  const zoneSelectedDoc = await zonesInfoCollection.get();
  const zones = zoneSelectedDoc.docs.map((doc) => ({
    shapesID: doc.id.trim(),
    zoneId: doc.id.trim(),
    ...doc.data(),
  }));
  console.log("zones:", zones);
  const mappedLocations = locations.map((l) => {
    const shapeZoneInfo: Zone | undefined = zones.find(
      (z) => z.shapesID === l.zone
    );
    return {
      postalCode: l.properties.Postcode,
      coordinates: l.geometry.coordinates,
      latitude: l.geometry.coordinates[1],
      longtitude: l.geometry.coordinates[0],
      zone: l.zone ? shapeZoneInfo?.value : "",
      region: shapeZoneInfo?.region ? shapeZoneInfo?.region : "",
    };
  });
  return mappedLocations;
}
export async function getPostalCode(postalcode: string) {
  const db = await mongoDbAppForPostalCode();
  const postalCodeCapital = postalcode.toUpperCase();
  console.log("postalCodeCapital:", postalCodeCapital);
  const postalCodeToSearch =
    postalCodeCapital.substr(0, 3) + " " + postalCodeCapital.substr(3);
  // console.log('postalCodeToSearch', postalCodeToSearch)
  const locations = await db
    .collection("postal_codes")
    .find({ "properties.Postcode": postalCodeToSearch });
  console.log("locations:", locations);
  return locations;
}
/**
 * @description - Get all postal code for a region.
 * @param  {string} region
 */
export async function getPostalCodeByRegion(
  region: string,
  environment: string,
  isService: boolean
) {
  // const environment = store.state.environment
  // https://us-central1-geospatial-41457.cloudfunctions.net/MAPPING-getPostalCodeByRegion
  // process.env.VUE_APP_GET_POSTAL_CODES_BY_REGION
  // const region = (store.state.region);
  const client: string = process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID;
  const postalCodesBucket = await axios.post(
    process.env.VUE_APP_GET_POSTAL_CODES_BY_REGION,
    {
      region: region || "",
      client: client || "",
      isService
    }
  );
  const postalCodesBucketUrl = postalCodesBucket.data.result;
  const convertPostalCodeToBinaryFile = await axios.post(
    isService
      ? process.env.VUE_APP_CONVERT_BINARY_SERVICE
      : process.env.VUE_APP_CONVERT_BINARY,
    {
      url: postalCodesBucketUrl,
      client: client || "",
      environment
    }
  );
  const binaryFileBucketUrl = convertPostalCodeToBinaryFile.data.result;
  /* Downloading the results from the storage url and passing to client */
  const binaryFile = await axios.get(binaryFileBucketUrl);
  return binaryFile;
}
export async function getZoneByRegion(region: string) {
  const mongoAppForShape = await mongoDbAppForShape();
  const zonesCollection = mongoAppForShape.collection("zones");
  const zones = await zonesCollection.findOne({
    region: region,
  });
  return zones;
}
