var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loading
      ? _c(
          "div",
          {},
          [
            _c("md-list", { staticClass: "md-double-line" }, [
              _c("div", { staticClass: "title is-5" }, [_vm._v("Edit Shape")]),
              _c(
                "div",
                {
                  staticClass:
                    "is-size-6 has-text-weight-semibold has-text-danger",
                },
                [_vm._v(" " + _vm._s(_vm.msg) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-flex-direction-column is-flex-wrap-wrap is-align-items-baseline",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "is-size-6 is-flex is-flex-direction-row has-text-weight-semibold",
                    },
                    [_vm._v(" Zone: " + _vm._s(_vm.shape.zone) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "is-size-6 is-flex is-flex-direction-row has-text-weight-semibold",
                    },
                    [_vm._v(" Region: ")]
                  ),
                  _c("div", { staticClass: "is-size-6" }, [
                    _vm._v(_vm._s(_vm.shape.region)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "columns notification is-white" }, [
                _c("div", { staticClass: "column has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-info is-small",
                      on: {
                        click: function ($event) {
                          return _vm.cancelEdit()
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ]),
                _vm.editable
                  ? _c("div", { staticClass: "column has-text-centered" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-small is-primary",
                          on: { click: _vm.editFirestore },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ],
          1
        )
      : _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "flex-direction": "column",
              padding: "0",
              margin: "0",
            },
          },
          [_c("PulseLoader")],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }