var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "container is-desktop",
      staticStyle: { "max-width": "500px", margin: "0 auto" },
    },
    [
      _vm.showLoginMessageModal
        ? _c("LoginMessageModal", {
            on: {
              "close-modal": function ($event) {
                _vm.showLoginMessageModal = false
              },
              "first-time-login": (bool) => (_vm.firstTimeLogin = bool),
            },
          })
        : _vm._e(),
      !_vm.sent2FA && !_vm.needsEnrollment && !_vm.authenticating2FA
        ? _c(
            "div",
            {
              staticStyle: {
                position: "absolute top:10rem left: 15% width:70%",
              },
            },
            [
              !_vm.user
                ? _c(
                    "section",
                    {
                      staticClass: "notification",
                      attrs: { id: "firebaseui-auth-container" },
                    },
                    [
                      _c(
                        "form",
                        {
                          staticStyle: { "max-width": "500px margin: 0 auto" },
                        },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email,
                                  expression: "email",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                type: "text",
                                placeholder: "Email",
                                autocomplete: "",
                              },
                              domProps: { value: _vm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.email = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "field container" }, [
                            _c("ul", [
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "is-size-7 has-text-weight-semibold",
                                  class: {
                                    is_valid: _vm.containsEightCharacters,
                                  },
                                },
                                [_vm._v(" 8 Characters ")]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "is-size-7 has-text-weight-semibold",
                                  class: { is_valid: _vm.containsNumber },
                                },
                                [_vm._v(" Contains Number ")]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "is-size-7 has-text-weight-semibold",
                                  class: { is_valid: _vm.containsUppercase },
                                },
                                [_vm._v(" Contains Uppercase ")]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "is-size-7 has-text-weight-semibold",
                                  class: {
                                    is_valid: _vm.containsSpecialCharacter,
                                  },
                                },
                                [_vm._v(" Contains Special Character ")]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "checkmark_container",
                                class: { show_checkmark: _vm.validPassword },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "50%",
                                      height: "50%",
                                      viewBox: "0 0 140 100",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      staticClass: "checkmark",
                                      class: { checked: _vm.validPassword },
                                      attrs: { d: "M10,50 l25,40 l95,-70" },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.pass,
                                  expression: "pass",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                id: "password",
                                type: "password",
                                autocomplete: "",
                                placeholder: "Password",
                              },
                              domProps: { value: _vm.pass },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.pass = $event.target.value
                                  },
                                  _vm.checkPassword,
                                ],
                              },
                            }),
                          ]),
                          _c("div", {
                            staticClass:
                              "recaptchaContainer container is-fluid",
                          }),
                          _c("div", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.firstTimeLogin,
                                    expression: "!firstTimeLogin",
                                  },
                                ],
                                staticClass:
                                  "button field is-success is-fullwidth",
                                class: { "is-loading": _vm.isLoading },
                                attrs: {
                                  id: "sign-in",
                                  disabled: !_vm.validPassword,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.logIn.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" Login ")]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.firstTimeLogin,
                                    expression: "firstTimeLogin",
                                  },
                                ],
                                staticClass:
                                  "button field is-primary is-fullwidth",
                                class: { "is-loading": _vm.isLoading },
                                attrs: {
                                  id: "sign-up",
                                  disabled: !_vm.validPassword,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.signUp.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" Sign Up ")]
                            ),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.firstTimeLogin,
                                    expression: "firstTimeLogin",
                                  },
                                ],
                                staticClass: "is-size-7 button is-small",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function ($event) {
                                    _vm.firstTimeLogin = false
                                  },
                                },
                              },
                              [_vm._v(" Go to login ")]
                            ),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.firstTimeLogin,
                                    expression: "!firstTimeLogin",
                                  },
                                ],
                                staticClass: "is-size-7 button is-small",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function ($event) {
                                    _vm.firstTimeLogin = true
                                  },
                                },
                              },
                              [_vm._v(" Go to sign-up ")]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "container is-fullwidth" }, [
        _vm.sent2FA
          ? _c("div", { staticClass: "notification" }, [
              _c("label", { staticClass: "is-size-7 tag is-warning field" }, [
                _vm._v(" We just sent you a verification code. "),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.verificationCode,
                    expression: "verificationCode",
                  },
                ],
                staticClass: "input field",
                attrs: {
                  type: "number",
                  placeholder: "Please enter your verification code here.",
                },
                domProps: { value: _vm.verificationCode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.verificationCode = $event.target.value
                  },
                },
              }),
              _c(
                "button",
                {
                  staticClass: "button is-fullwidth is-primary",
                  class: { "is-loading": _vm.isLoading },
                  on: { click: _vm.verifyCode },
                },
                [_vm._v(" Submit ")]
              ),
              _c("label", { staticClass: "is-size-7 tag is-light is-info" }, [
                _vm._v(" Standard rates may apply. "),
              ]),
            ])
          : _vm._e(),
        _vm.needsEnrollment
          ? _c("div", { staticClass: "notification" }, [
              _vm._m(0),
              _vm.getIsBestBuy
                ? _c("input", {
                    staticClass: "input field",
                    attrs: { type: "tel", placeholder: "Phone number..." },
                    domProps: { value: _vm.last2Digits },
                  })
                : _vm._e(),
              !_vm.getIsBestBuy
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.authPhoneNumber,
                        expression: "authPhoneNumber",
                      },
                    ],
                    staticClass: "input field",
                    attrs: { type: "tel", placeholder: "Phone number..." },
                    domProps: { value: _vm.authPhoneNumber },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.authPhoneNumber = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
              _vm.registerVerificationId
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registrationVerificationCode,
                        expression: "registrationVerificationCode",
                      },
                    ],
                    staticClass: "input field",
                    attrs: {
                      type: "number",
                      placeholder: "Please enter your verification code.",
                    },
                    domProps: { value: _vm.registrationVerificationCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.registrationVerificationCode = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
              _vm.registerVerificationId
                ? _c(
                    "button",
                    {
                      staticClass: "button is-fullwidth is-primary",
                      class: { "is-loading": _vm.isLoading },
                      on: { click: _vm.verifyCodeForEnrollment },
                    },
                    [_vm._v(" Submit ")]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      !_vm.needsEnrollment
        ? _c(
            "div",
            { staticStyle: { "margin-top": "100px" } },
            [
              _vm.user
                ? _c("div", { staticClass: "field" }, [
                    _vm._v(
                      " Hang tight, we are redirecting you to the portal. "
                    ),
                  ])
                : _vm._e(),
              _vm.user
                ? _c("md-progress-spinner", {
                    attrs: {
                      "md-diameter": 50,
                      "md-stroke": 2,
                      "md-mode": "indeterminate",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.sent2FA && _vm.captchaExpired
        ? _c("div", { staticClass: "notification is-warning" }, [
            _c("button", {
              staticClass: "delete",
              on: {
                click: function ($event) {
                  _vm.captchaExpired = false
                },
              },
            }),
            _vm._v(" Session expired. Please attempt again. "),
          ])
        : _vm._e(),
      _c(
        "md-snackbar",
        {
          attrs: {
            id: "snackbar",
            "md-position": _vm.position,
            "md-duration": _vm.isInfinity ? Infinity : _vm.duration,
            "md-active": _vm.showSnackbar,
            "md-persistent": "",
          },
          on: {
            "update:mdActive": function ($event) {
              _vm.showSnackbar = $event
            },
            "update:md-active": function ($event) {
              _vm.showSnackbar = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.message))]),
          _c(
            "md-button",
            { staticClass: "md-primary", on: { click: _vm.update } },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "recaptchaContainer2 container is-fluid" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { padding: "8px" } }, [
      _c("strong", [_vm._v(" Secure Portal ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }