<template>
  <div>
    <l-map
      ref="map"
      v-bind:style="mapStyling"
      :zoom="zoom"
      :maxZoom="maxZoom"
      :center="center"
      :options="mapOptions"
      @update:zoom="zoomUpdated"
      @update:bounds="boundsUpdated"
    >
      <l-control position="topright" id="v-step-0">
        <button
          data-tooltip="Left tooltip content"
          v-bind:class="
            refreshLoading
              ? 'button is-small is-loading is-success is-outlined is-rounded is-inverted'
              : 'button is-small is-success is-outlined is-rounded is-inverted is-hovered'
          "
          @click="refresh"
        >
          <span class="icon">
            <i class="fas fa-redo has-text-success"></i>
          </span>
        </button>
      </l-control>
      <l-control-zoom
        position="topright"
        class="leaflet-control-zoom"
      ></l-control-zoom>
      <l-tile-layer :url="url"></l-tile-layer>
    </l-map>
    <div
      style="
        position: fixed;
        bottom: 0;
        z-index: 1000;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.8);
      "
      class="is-centered is-mobile has-text-centere py-3"
    >
      <div class="select is-small is-success mx-2">
        <select
          style="width: 120px"
          id="v-step-1"
          @change="selectRegion"
          :disabled="retrievingFSA"
        >
          <option
            v-for="option in regions"
            :selected="option.id == $store.state.region"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
      <button
        v-if="
          selectableFSAMode &&
          selectedFSAToAdd.length > 0 &&
          !editSelectableFSAMode
        "
        class="button is-small is-success mx-2 is-outlined"
        @click="openAddFSAShapeZoneModal"
      >
        Confirm FSA Zones
      </button>
      <button
        v-if="
          selectableFSAMode &&
          selectedFSAToAdd.length > 0 &&
          editSelectableFSAMode
        "
        class="button is-small is-success mx-2 is-outlined"
        @click="openEditFSAShapeZoneModal"
      >
        Save FSA Zones
      </button>
      <button
        v-if="deletePartShapeMode"
        class="button is-small is-success mx-2 is-outlined"
        @click="closeAddDeletePartShapeMode()"
      >
        Close Drawing Mode
      </button>
      <button
        class="is-small is-success button mx-2"
        role="navigation"
        aria-label="dropdown navigation"
      >
        <div class="navbar-item has-dropdown-up is-hoverable is-small">
          <button class="is-small is-success button" id="v-step-3">Menu</button>
          <div class="navbar-dropdown">
            <a
              class="navbar-item"
              @click="showExportModal = true"
              v-if="!retrievingFSA"
            >
              Export Data
            </a>
            <hr class="navbar-divider" />
            <!--
            <a
              class="navbar-item"
              v-if="
                !addMoreShapeMode && !deletePartShapeMode && !FSAConfirmMode
              "
              @click="
                selectableFSAMode
                  ? closeSelectableFSAMode(false)
                  : selectableFSAModeOn(false)
              "
            >
              Toggle FSAs visiability
            </a>
            <a class="navbar-item" @click="showFSASelector = true">
              Switch FSA Province
            </a>-->
            <a
              class="navbar-item"
              @click="$router.push(`/Environments?id=${$route.query.id}`)"
            >
              Toggle Environment
            </a>
          </div>
        </div>
      </button>
    </div>
    <notifications group="fsa" position="bottom left" />
    <notifications
      group="export"
      position="top left"
      class="export_notification"
    />
    <notifications group="zones" position="bottom right" />
    <notifications
      group="postal_code_search"
      position="top right"
      class="postal-code-search"
    />
    <FSASelector
      :showFSASelector="showFSASelector"
      :retrievingFSA="retrievingFSA"
      @closeModal="showFSASelector = false"
      @selectFSAProvince="selectFSAProvince"
      @closeSelectableFSAMode="closeSelectableFSAMode"
      v-if="mappingLoaded"
    />
    <ExportModal
      :showExportModal="showExportModal"
      @closeModal="showExportModal = false"
      v-if="mappingLoaded"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { LMap, LTileLayer, LControlZoom, LControl } from "vue2-leaflet";
import { mapState } from "vuex";
import centerMass from "@turf/center-of-mass";
import { polygon as polygonTurf } from "@turf/turf";
import { GoogleProvider, SearchControl } from 'leaflet-geosearch';
import AddFSAZoneModal from "@/components/AddFSAZoneModal.vue";
import EditFSAZoneModal from "@/components/EditFSAZoneModal.vue";
import EditShape from "../utils/components/EditShape";
import EditMarker from "../utils/components/EditMarker";

import {
  hubsCollection,
  zonesInfoCollection,
  regionsCollection,
} from "../store/fireStore";
import {
  getZones,
  getFSA,
  getPostalCode,
  mongoDbAppForShape,
} from "../store/mongoStore";
import { regionCenter } from "./utils";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-toolbar";
import "leaflet.markercluster";
import "overlapping-marker-spiderfier-leaflet/dist/oms";
const OverlappingMarkerSpiderfier = window.OverlappingMarkerSpiderfier;
delete window.L.Icon.Default.prototype._getIconUrl;
window.L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
let workerSelectFSA;
let workerCombineSelectedFSA;
export default {
  name: "Views",
  components: {
    FSASelector: () => import("@/components/FSASelector.vue"),
    ExportModal: () => import("@/components/ExportModal"),
    LMap,
    LTileLayer,
    LControlZoom,
    LControl,
  },
  data() {
    return {
      distzoneListenerUnsubscribe: null,
      spiderfy: null,
      mappingLoaded: false,
      importingLoading: false,
      refreshLoading: false,
      showVehicleModal: false,
      showZoneListModal: false,
      showChangingRegionModal: false,
      showHistoryModal: false,
      showQueuePopUp: false,
      searchLoading: false,
      showRouteHubModal: false,
      showFSASelector: false,
      showRegionList: false,
      showImportModal: false,
      showExportModal: false,
      url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${process.env.VUE_APP_STADIA_API}`,
      zoom: 10,
      maxZoom: 20,
      center: [45.203427, -76.016414],
      bounds: null,
      map: null,
      postalCodeSearch: "",
      drawnZones: [],
      drawnMarkers: [],
      drawnMarkersPostalCodes: [],
      drawnFSA: [],
      zones: [],
      markers: [],
      regions: [],
      selectedFSAProvince: "AB",
      editedZones: [],
      vehicles: [],
      vehicleObj: {},
      drawControl: null,
      markerGroup: null,
      markerGroupFSA: null,
      addDeletePartShapeModeDrawControl: null,
      FSALayerGroup: null,
      FSANameLayerGroup: null,
      FSALayerControl: null,
      retrievingFSA: false,
      drawnItems: null,
      FSAItems: null,
      postalCodesGroup: null,
      selectedFSAToAdd: [],
      toolbarStyling: {
        width: "0vw",
      },
      mapStyling: {
        width: "100vw",
      },
      mapContainerStyling: {
        position: "initial",
      },
      mapOptions: {
        zoomControl: false,
        // preferCanvas: true
      },
      selectedShape: null,
      currentSelectedFSAShape: [],
      toBeSaveShapeFSAID: null,
      editShapeSelectedMode: false,
      addMoreShapeMode: false,
      deletePartShapeMode: false,
      selectableFSAMode: false,
      editSelectableFSAMode: false,
      FSAConfirmMode: false,
      postalCodeMarkerShow: false,
      initialLoadRegion: false,
      queue: [],
      busy: false,
      onEditProcess: false,
      currentAction: null,
    };
  },
  watch: {
    region: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        if (this.distzoneListenerUnsubscribe) {
          this.distzoneListenerUnsubscribe();
        }
        // initial get all zones
        const drawnMarkers = new window.L.FeatureGroup();
        try {
          this.distzoneListenerUnsubscribe = hubsCollection
            .where("Region", "==", `${newVal}`)
            .onSnapshot((snapshot) => {
              const drawnHubs = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              this.$store.dispatch("setHubs", drawnHubs);
              this.markers = drawnHubs.filter(
                (shape) => shape.regionHub === this.$store.state.region
              );
              this.drawnMarkers
                .map((d) => d.leafletId)
                .forEach((id) => {
                  this.map.removeLayer(this.map._layers[id]);
                });
              this.drawnMarkers = [];
              for (const x in drawnMarkers._layers) {
                drawnMarkers.removeLayer(drawnMarkers._layers[x]);
              }
              drawnHubs.map((DistributionZone) => {
                const coordinates = {
                  lat: DistributionZone.Center.latitude,
                  lng: DistributionZone.Center.longitude,
                };
                const color = DistributionZone.Color;
                /* 0, 1, 2: MAIN, SUB, STORE */
                const distributionZoneType =
                  DistributionZone.DistributionZoneType;
                const DistributionZoneId = DistributionZone.DistributionZoneId;
                const marker = new window.L.Marker(coordinates, {
                  icon: this.getIcon(color, distributionZoneType),
                  riseOnHover: true,
                }).addTo(this.map);
                //   const zone = shape.zone
                const instance = EditMarker(
                  this.$store,
                  this.map,
                  DistributionZone,
                  false /* is editable */
                );
                instance.$mount();
                const contentPopup = instance.$el;
                const markerProperties = {
                  leafletId: marker._leaflet_id,
                  coordinates,
                  id: DistributionZoneId,
                  region: DistributionZone.regionHub,
                };
                this.drawnMarkers.push(markerProperties);
                marker.bindPopup(contentPopup);
                drawnMarkers.addLayer(marker);
                // add marker to oms instance
                this.spiderfy.addMarker(marker);
              });
              this.center = regionCenter(
                this.regions.filter((r) => r.id === newVal)[0].name
              );
            });
        } catch (error) {
          console.log("error", error);
        }
      },
    },
  },
  methods: {
    /* Select the province for which to load the FSAs */
    async selectFSAProvince(selectedFSAProvince) {
      if (this.selectedFSAProvince === "") {
        this.$notify({
          type: "warn",
          group: "export",
          title: "No selected provice",
          text: "Please select a region",
          duration: 20000,
        });
        return;
      }
      this.showChangingRegionModal = true;
      this.closeSelectableFSAMode(true);
      this.center = regionCenter(selectedFSAProvince);
      this.map.removeLayer(this.FSALayerGroup);
      this.map.removeLayer(this.FSANameLayerGroup);
      this.map.removeControl(this.FSALayerControl);
      // this.renderFSAToMap(selectedFSAProvince);
      this.showFSASelector = false;
    },
    /* Update map zoon */
    zoomUpdated(zoom) {
      this.zoom = zoom;
      this.mapContainerStyling = {
        position: "initial",
      };
    },
    /* Update map bounds */
    boundsUpdated(bounds) {
      this.bounds = bounds;
      this.mapContainerStyling = {
        position: "initial",
      };
    },
    /**
     * @param  {e} event - value of selected region
     */
    async selectRegion(e) {
      const newRegion = e.target.value;
      const selectedFleet = this.vehicleObj[newRegion];
      this.vehicles = selectedFleet;
      this.$store.dispatch("setRegion", newRegion);
      this.$store.dispatch("setSelectedVehicles", selectedFleet);
      this.$store.dispatch("setTrucksToBeAssigned", []);
      this.zones = this.$store.state.zoneInformations.filter(
        (doc) => doc.environment === this.$store.state.environment
      );
      // this is to avoid getting the fsa of the same province
      setTimeout(async () => {
        await this.renderZonesToMap(true, newRegion);
      }, 500);
    },
    /**
     * Search Postal Code
     */
    async searchPostalCode() {
      // const currentRegion = this.$store.state.region
      this.searchLoading = true;
      try {
        this.postalCodesGroup.clearLayers();
        const postalCodeSearch = this.postalCodeSearch
          .trim()
          .replace(/\s/g, "");
        console.log("postalCodeSearch:", postalCodeSearch);
        const result = await getPostalCode(postalCodeSearch);
        if (result.length > 0) {
          console.log("result:", result);
          const [long, lat] = result[0].geometry.coordinates;
          console.log("long:", long);
          console.log("lat:", lat);
          result.forEach((postalCode) => {
            const coordinates = [
              postalCode.geometry.coordinates[1],
              postalCode.geometry.coordinates[0],
            ];
            console.log(coordinates);
            const marker = new window.L.Marker(coordinates, {
              icon: this.getIcon("yellow"),
              riseOnHover: true,
            });
            this.postalCodesGroup.addLayer(marker);
          });
          this.postalCodeMarkerShow = true;
          this.$notify({
            group: "postal_code_search",
            title: "postal code exist",
            closeOnClick: true,
            duration: 3000,
            text: "The postal code you tried to find exists",
          });
          this.center = [lat, long];
        } else {
          this.$notify({
            group: "postal_code_search",
            title: `Cannot find postal code ${postalCodeSearch}`,
            closeOnClick: true,
            duration: 3000,
            text: "The postal code you tried to find does not exist",
          });
        }
      } catch (err) {
        this.$notify({
          group: "postal_code_search",
          title: "Error on finding postal code",
          closeOnClick: true,
          duration: 3000,
          text: "Please try again",
        });
      }
      this.searchLoading = false;
    },
    /**
     * Return Icon indicator for DISTRIBUTIONZONES
     */
    getIcon(color, type) {
      let svgString;
      if (type === "STORE") {
        svgString = `<svg style="fill:${color}" width="20px" height="20px" viewBox="0 0 20 20"><path d="M13 17a2 2 0 1 0 3.999.001A2 2 0 0 0 13 17zM3 17a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm3.547-4.828L17.615 9.01A.564.564 0 0 0 18 8.5V3H4V1.4c0-.22-.181-.4-.399-.4H.399A.401.401 0 0 0 0 1.4V3h2l1.91 8.957.09.943v1.649c0 .219.18.4.4.4h13.2c.22 0 .4-.182.4-.4V13H6.752c-1.15 0-1.174-.551-.205-.828z"></path></svg>`;
      } else if (type === "SUPPLIER") {
        svgString = `<svg fill="${color}" width="25px" height="25px" viewBox="0 0 33 32"><path d="M32.45,8.44,22,15.3V9.51a1,1,0,0,0-1.63-.78L14.07,14H10V4.06L4,2.71V14H2V31a1,1,0,0,0,1,1H33a1,1,0,0,0,1-1V9.27A1,1,0,0,0,32.45,8.44ZM14,29H6V27h8Zm0-4H6V23h8Zm0-4H6V19h8Zm8,8H20V26h2Zm0-6H20V20h2Zm4,6H24V26h2Zm0-6H24V20h2Zm4,6H28V26h2Zm0-6H28V20h2Z"></path></svg>`;
      } else {
        svgString = `<svg version="1.1" viewBox="0 0 20 34" height="45" width="45" enable-background="new 0 0 18 18" style="fill:${
          color || "black"
        }"><g><polygon points="16,5 9,1 2,5 2,17 4,17 4,7 14,7 14,17 16,17"></polygon><rect x="5" y="15" width="2" height="2"></rect><rect x="8" y="15" width="2" height="2"></rect><rect x="11" y="15" width="2" height="2"></rect><rect x="5" y="12" width="2" height="2"></rect><rect x="8" y="12" width="2" height="2"></rect><rect x="11" y="12" width="2" height="2"></rect><rect x="5" y="9" width="2" height="2"></rect><rect x="8" y="9" width="2" height="2"></rect></g></svg>`;
      }
      return window.L.divIcon({
        className: "my-custom-pin",
        html: svgString,
      });
    },
    /**
     * To render all of the zones to the map
     * Usually called after editing or writing on the Zone database
     */
    async renderZonesToMap(isGettingZone, region) {
      // this is to render all zones to the map
      const province = region;
      const friendlyName = this.regions.find((r) => r.id === province)
        ? this.regions.find((r) => r.id === province).name
        : "";
      if (isGettingZone) {
        this.$notify({
          group: "zones",
          title: "Getting Zones",
          closeOnClick: false,
          duration: -1,
          text: `Retrieving zones for ${friendlyName}, please wait...`,
        });
      } else {
        this.$notify({
          group: "zones",
          clean: true,
        });
        this.$notify({
          group: "zones",
          title: "Processing",
          closeOnClick: false,
          duration: -1,
          text: "Data processing, please wait...",
        });
      }
      try {
        this.showChangingRegionModal = true;
        const res = await getZones(province);
        const drawnShapes = res.map((doc) => ({ id: doc._id, ...doc }));
        this.$store.dispatch("setRegionZones", drawnShapes);
        this.zones = this.$store.state.zoneInformations;
        this.drawnZones.forEach((d) => {
          this.map.removeLayer(this.map._layers[d.leafletId]);
        });
        this.markerGroup.clearLayers();
        this.drawnZones = [];
        for (const x in this.drawnItems._layers) {
          this.drawnItems.removeLayer(this.drawnItems._layers[x]);
        }
        drawnShapes.map((shape) => {
          const coordinatesForLeaflet = shape.multi
            ? JSON.parse(shape.coordinates).map((shape) => {
                return shape.map((c) => [c[1], c[0]]);
              })
            : JSON.parse(shape.coordinates).map((c) => [c[1], c[0]]);
          const invertedOrderCoordinates = JSON.parse(shape.coordinates);
          const color = shape.color;
          const zone = shape.zone;
          const polygon = window.L.polygon(coordinatesForLeaflet, {
            color: color,
            fillOpacity: 0.3,
            weight: 0.5,
          }).addTo(this.map);
          const tooltip = window.L.tooltip({
            permanent: true,
            direction: "center",
            opacity: "1",
            className: "zone-tooltip",
          });
          tooltip.setContent(
            `<div style='background-color: transparent'> ${zone} </div>`
          );
          const { lat, lng } = polygon.getCenter();
          tooltip.setLatLng(new window.L.LatLng(lat, lng));
          this.markerGroup.addLayer(tooltip);
          const instance = EditShape(
            this.$store,
            invertedOrderCoordinates,
            shape,
            this.$store.state.selectedVehicles,
            this.map,
            false /* is editable */,
            this.editCurrentShapeModeOn,
            this.renderZonesToMap,
            this.activateEditSelectableFSAMode,
            this.closeEditCurrentShapeMode
          );
          instance.$mount();
          const contentPopup = instance.$el;
          const zoneProperties = {
            zone,
            leafletId: polygon._leaflet_id,
            coordinates: coordinatesForLeaflet,
            id: shape.id,
            shapeId: shape.id,
            zoneId: shape.zoneId,
            region: shape.region,
            multi: shape.multi,
            invertedCoordinates: invertedOrderCoordinates,
            daysAssigned: shape.daysAssigned,
            fsaIDs: shape.fsa,
          };
          this.drawnZones.push(zoneProperties);
          const popupContent = window.L.popup({
            autoPan: false,
            closeButton: false,
            autoClose: false,
          }).setContent(contentPopup);
          polygon.bindPopup(popupContent);
          this.drawnItems.addLayer(polygon);
        });
        this.$notify({
          group: "zones",
          clean: true,
        });
        this.$notify({
          type: "success",
          group: "zones",
          title: isGettingZone ? "Zones were retrieved" : "Zones were fetched",
          text: "Map is updated",
          duration: 3000,
        });
        this.showChangingRegionModal = false;
      } catch (err) {
        if (err.name !== "TypeError") {
          this.$notify({
            group: "zones",
            clean: true,
          });
          this.$notify({
            type: "error",
            group: "zones",
            title: "There was an error, please refresh the page again",
          });
        }
      }
    },
    /**
     * To render all of the FSA to the map
     * Automatically the app will be on add FSA mode
     */

    async renderFSAToMap(province) {
      this.drawnFSA = [];
      const FSALayerGroup = window.L.featureGroup();
      const FSANameLayerGroup = window.L.featureGroup();
      this.FSALayerGroup = FSALayerGroup;
      this.FSANameLayerGroup = FSANameLayerGroup;
      this.retrievingFSA = true;
      this.$notify({
        group: "fsa",
        title: "Getting FSA",
        closeOnClick: false,
        duration: -1,
        text: "Retrieving FSA, please wait...",
      });
      const res = await getFSA(province);
      try {
        const fsaLocations = res;
        this.markerGroupFSA.clearLayers();
        fsaLocations.map((shape) => {
          const coordinatesForLeaflet = JSON.parse(shape.coordinates).map(
            (c) => [c[1], c[0]]
          );
          const invertedCoordinates = JSON.parse(shape.coordinates);
          const FSA = shape.FSA;
          const FSA_ID = shape.FSA_ID;
          const polygon = window.L.polygon(coordinatesForLeaflet, {
            color: "black",
            weight: 1,
            fill: true,
            fillColor: "#A2D26C",
            fillOpacity: 0.4,
          }).addTo(FSALayerGroup);
          const FSAProperties = {
            FSA,
            FSA_ID,
            coordinates: coordinatesForLeaflet,
            invertedCoordinates,
            id: polygon._leaflet_id,
          };
          this.drawnFSA.push(FSAProperties);
          // make fsa clickbale
          polygon.on("click", async (e) => {
            const selectedFSAId = e.sourceTarget._leaflet_id;
            const selectedFSAShape = this.drawnFSA.find(
              (d) => d.id === selectedFSAId
            );
            console.log("selectedFSAShape.FSA_ID: ", selectedFSAShape.FSA_ID);
            console.log("selectedFSAShape: ", selectedFSAShape);
            if (this.selectableFSAMode) {
              const selectedFSAShapeToAdd = this.selectedFSAToAdd.find(
                (d) => d.id === selectedFSAId
              );
              if (!selectedFSAShapeToAdd) {
                workerSelectFSA.postMessage({
                  drawnZones: this.drawnZones,
                  editSelectableFSAMode: this.editSelectableFSAMode,
                  selectedShapeID: this.selectedShape && this.selectedShape.id,
                  selectedFSAShape,
                });
              } else {
                console.log("already added");
                this.selectedFSAToAdd = this.selectedFSAToAdd.filter(
                  (d) => d.id !== selectedFSAId
                );
                await this.renderSelectedFSAShape();
              }
            }
          });
          const polygonFSA = polygonTurf([coordinatesForLeaflet]);
          const {
            geometry: { coordinates: centerPolygon },
          } = centerMass(polygonFSA);
          const tooltip = window.L.tooltip({
            permanent: true,
            sticky: true,
            direction: "center",
            opacity: "1",
            className: "FSA_tooltip",
            pane: "tooltipPane",
          });
          tooltip.setContent(`${FSA}`);
          tooltip.setLatLng(centerPolygon);
          this.markerGroupFSA.addLayer(tooltip);
        });
        const overLayerMaps = {
          "FSA Layer": FSALayerGroup,
          "FSA Name": this.markerGroupFSA,
        };
        const layerControl = window.L.control.layers(null, overLayerMaps, {
          position: "topright",
        });
        this.FSALayerControl = layerControl;
        window.FSALayerControl = layerControl;
        layerControl.addTo(this.map);
        if (this.$store.state.isAdmin) {
          layerControl.addTo(this.map);
        }
        const fsaTargetElement = document.querySelector(
          ".leaflet-control-layers-overlays > :first-child"
        );
        const checkboxElement = fsaTargetElement.querySelector(
          ".leaflet-control-layers-selector"
        );
        const appThis = this;
        checkboxElement.addEventListener("change", async function () {
          if (this.checked) {
            await appThis.selectableFSAModeOn(true);
          } else {
            await appThis.closeSelectableFSAMode(false, true);
          }
        });
        this.$notify({
          group: "fsa",
          clean: true,
        });
        this.$notify({
          type: "success",
          group: "fsa",
          title: "FSA retrieved",
          text: `Fetched all FSA from ${province}`,
        });
        this.retrievingFSA = false;
        this.showChangingRegionModal = false;
        window.FSALayerGroup = this.FSALayerGroup;
      } catch (err) {
        this.$notify({
          group: "fsa",
          clean: true,
        });
        this.$notify({
          type: "error",
          group: "fsa",
          title: "FSA not retrieved",
          text: `${err}`,
        });
        this.retrievingFSA = false;
        this.showChangingRegionModal = false;
      }
    },
    /**
     * Open Modal on FSA mode when adding new shape
     */
    openAddFSAShapeZoneModal() {
      // after selecting some fsa
      // this will checker of the formats of the shape before
      // show the modal to comfirm it
      workerCombineSelectedFSA.postMessage({
        selectedFSAToAdd: this.selectedFSAToAdd,
        drawnFSA: this.drawnFSA,
        mode: "ADD",
      });
    },
    /**
     * Show FSA of the current region
     */
    selectableFSAModeOn(clickByCheckBox = false) {
      // this will activate selectable fsa mdoe and show fsa layer
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      const checkboxElement = fsaTargetElement.querySelector(
        ".leaflet-control-layers-selector"
      );
      this.map.removeControl(this.drawControl);
      this.map.closePopup();
      this.selectableFSAMode = true;
      // this is for activating fsa layer manually
      if (!clickByCheckBox) {
        checkboxElement.click();
        fsaTargetElement.style.pointerEvents = "none";
      }
    },
    /**
     * Activate FSA by selecting a shape then render current FSA assign on the current selected shape
     */
    async activateEditSelectableFSAMode() {
      // this will trigger to show all fsa to be clickable
      // this also will show all the fsa that the current shape overlaps
      this.map.removeControl(this.drawControl);
      this.map.closePopup();
      if (!this.selectableFSAMode) {
        const fsaTargetElement = document.querySelector(
          ".leaflet-control-layers-overlays > :first-child"
        );
        const checkboxElement = fsaTargetElement.querySelector(
          ".leaflet-control-layers-selector"
        );
        checkboxElement.click();
        fsaTargetElement.style.pointerEvents = "none";
      }
      this.selectableFSAMode = true;
      this.editSelectableFSAMode = true;
      const selectedShape = this.selectedShape;
      await this.drawnFSA.forEach(async (fsa) => {
        try {
          // fsaIDs // FSA_ID
          if (selectedShape.fsaIDs.includes(fsa.FSA_ID)) {
            this.selectedFSAToAdd.push(fsa);
            await this.renderSelectedFSAShape();
          }
        } catch (err) {
          console.error("err: ", err);
        }
      });
      console.log("this.selectedFSAToAdd: ", this.selectedFSAToAdd);
    },
    /**
     * Hide FSA layers
     */
    closeSelectableFSAMode(changeRegion = false, checkByCheckbox = false) {
      if (!changeRegion) {
        const fsaTargetElement = document.querySelector(
          ".leaflet-control-layers-overlays > :first-child"
        );
        const checkboxElement = fsaTargetElement.querySelector(
          ".leaflet-control-layers-selector"
        );
        fsaTargetElement.style.pointerEvents = "";
        if (!checkByCheckbox) {
          checkboxElement.click();
        }
      }
      if (
        this.toBeSaveShapeFSAID &&
        this.map._layers[this.toBeSaveShapeFSAID]
      ) {
        this.map.removeLayer(this.map._layers[this.toBeSaveShapeFSAID]);
        this.toBeSaveShapeFSAID = null;
      }
      if (this.currentSelectedFSAShape.length > 0) {
        this.currentSelectedFSAShape.forEach((fsa) => {
          if (this.map._layers[fsa.id]) {
            this.map.removeLayer(this.map._layers[fsa.id]);
          }
        });
      }
      this.selectedShape = null;
      this.selectedFSAToAdd = [];
      this.currentSelectedFSAShape = [];
      this.selectableFSAMode = false;
      this.editSelectableFSAMode = false;
      this.FSAConfirmMode = false;
      this.onEditProcess = false;
    },
    /**
     * To render every FSA on click
     */
    renderSelectedFSAShape() {
      // console.log('RENDER THIS', this.selectedFSAToAdd)
      const wholeSelectedZone = this.selectedFSAToAdd.map((fsa) => ({
        coordinates: fsa.coordinates,
        fsaId: fsa.id,
      }));
      if (wholeSelectedZone.length > 0) {
        this.currentSelectedFSAShape.forEach((fsaProperty) => {
          this.map.removeLayer(this.map._layers[fsaProperty.id]);
        });
        this.currentSelectedFSAShape = [];
        wholeSelectedZone.map((fsa) => {
          const polygon = window.L.polygon([fsa.coordinates], {
            color: "black",
            weight: 1,
            fill: true,
            fillColor: "black",
            fillOpacity: 0.8,
            pane: "shadowPane",
          }).addTo(this.map);
          const selectedShapeProperty = {
            id: polygon._leaflet_id,
          };
          this.currentSelectedFSAShape.push(selectedShapeProperty);
          polygon.on("click", async (e) => {
            const selectedCurrentFSAId = e.sourceTarget._leaflet_id;
            this.map.removeLayer(this.map._layers[selectedCurrentFSAId]);
            this.currentSelectedFSAShape = this.currentSelectedFSAShape.filter(
              (d) => d.id !== selectedCurrentFSAId
            );
            this.selectedFSAToAdd = this.selectedFSAToAdd.filter(
              (d) => d.id !== fsa.fsaId
            );
          });
        });
      }
    },
    /**
     * Confirming shape after you the select it and show modal to choose what zone to assign
     */
    async renderFSAZoneToAddModal(
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      // this is the confirmation after adding some selected fsa and
      // display the modal
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      // const checkboxElement = fsaTargetElement.querySelector(
      //   '.leaflet-control-layers-selector'
      // )
      fsaTargetElement.style.pointerEvents = "none";
      this.FSAConfirmMode = true;
      this.selectableFSAMode = false;
      this.currentSelectedFSAShape.forEach((fsaProperty) => {
        this.map.removeLayer(this.map._layers[fsaProperty.id]);
      });
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: "black",
        weight: 1,
        fill: true,
        fillColor: "black",
        fillOpacity: 0.8,
        // pane: 'tilePane'
      });
      // .addTo(this.map)
      // console.log('invertedCenterCoordinate: ', invertedCenterCoordinate)
      this.drawnItems.addLayer(polygon);
      const ComponentClass = Vue.extend(AddFSAZoneModal);
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          region: this.$store.state.region,
          shapeType,
        },
        methods: {
          close: () => {
            this.map.closePopup();
          },
          updateMap: async (data) => {
            const { shapesToAdd, zoneId, shapeType } = data;
            if (shapeType === "SINGLE_SHAPE") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates[0],
                shapesID: zoneId,
                multi: false,
                fsa,
              });
            } else if (shapeType === "SINGLE_SHAPE_WITH_HOLES") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates,
                shapesID: zoneId,
                multi: true,
                fsa,
              });
            } else if (shapeType === "MULTI_SINGLE_SHAPE") {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  this.pushToQueue("INSERT_NEW_SHAPE", {
                    coordinates: coordinates,
                    shapesID: zoneId,
                    multi: false,
                    fsa,
                  });
                })
              );
            } else {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  if (coordinates.length > 1) {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates,
                      shapesID: zoneId,
                      multi: true,
                      fsa,
                    });
                  } else {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates[0],
                      shapesID: zoneId,
                      multi: false,
                      fsa,
                    });
                  }
                })
              );
            }
          },
          removeShape: async () => {
            this.toBeSaveShapeFSAID = null;
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
            await this.closeSelectableFSAMode();
          },
          cancelEdit: async () => {
            await this.closeSelectableFSAMode();
          },
        },
      });
      instance.$mount();
      const contentPopup = instance.$el;
      const popupContent = window.L.popup({
        autoPan: false,
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
      }).setContent(contentPopup);
      polygon.bindPopup(popupContent).openPopup();
      this.toBeSaveShapeFSAID = polygon._leaflet_id;
    },
    /**
     * Confirming shape after adding to show modal what zone to assign
     */
    async renderNewZoneToAddModal(
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: "black",
        weight: 1,
        fill: true,
        fillColor: "black",
        fillOpacity: 0.8,
      });
      this.drawnItems.addLayer(polygon);
      const ComponentClass = Vue.extend(AddFSAZoneModal);
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          region: this.$store.state.region,
          shapeType,
        },
        methods: {
          close: () => {
            this.$notify({
              group: "zones",
              clean: true,
            });
            this.map.closePopup();
          },
          updateMap: async (data) => {
            const { shapesToAdd, zoneId, shapeType } = data;
            if (shapeType === "SINGLE_SHAPE") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates[0],
                shapesID: zoneId,
                multi: false,
                fsa,
              });
            } else if (shapeType === "SINGLE_SHAPE_WITH_HOLES") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates,
                shapesID: zoneId,
                multi: true,
                fsa,
              });
            } else if (shapeType === "MULTI_SINGLE_SHAPE") {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  this.pushToQueue("INSERT_NEW_SHAPE", {
                    coordinates: coordinates,
                    shapesID: zoneId,
                    multi: false,
                    fsa,
                  });
                })
              );
            } else {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  if (coordinates.length > 1) {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates,
                      shapesID: zoneId,
                      multi: true,
                      fsa,
                    });
                  } else {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates[0],
                      shapesID: zoneId,
                      multi: false,
                      fsa,
                    });
                  }
                })
              );
            }
          },
          removeShape: async () => {
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
          },
          cancelEdit: async () => {
            this.map.closePopup();
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
          },
        },
      });
      instance.$mount();
      const contentPopup = instance.$el;
      const popupContent = window.L.popup({
        autoPan: false,
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
      }).setContent(contentPopup);
      polygon.bindPopup(popupContent).openPopup();
    },
    /**
     * Confirming shape after you the select it and show modal to choose what zone to assign
     */
    async renderFSAZoneToEditModal(
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      // this is the confirmation after adding some selected fsa and
      // display the modal
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      // const checkboxElement = fsaTargetElement.querySelector(
      //   '.leaflet-control-layers-selector'
      // )
      fsaTargetElement.style.pointerEvents = "none";
      this.FSAConfirmMode = true;
      this.selectableFSAMode = false;
      const selectedShape = this.selectedShape;
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: "black",
        weight: 1,
        fill: true,
        fillColor: "black",
        fillOpacity: 0.8,
        // pane: 'tilePane'
      });
      this.drawnItems.addLayer(polygon);
      const ComponentClass = Vue.extend(EditFSAZoneModal);
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          shapeType,
          shape: selectedShape,
        },
        methods: {
          close: () => {
            this.map.closePopup();
          },
          updateZone: async (state) => {
            const { coordinates, shapeId, multi, fsa } = state;
            this.onEditProcess = true;
            await this.pushToQueue("EDIT_COORDINATES", {
              coordinates,
              shapeId,
              multi,
              fsa,
            });
          },
          updateMultipleZone: async (state) => {
            const { newShapes, currentShape, shapeType } = state;
            this.onEditProcess = true;
            this.pushToQueue("EDIT_MULTIPLE_ZONE_FSA", {
              newShapes,
              currentShape,
              shapeType,
            });
          },
          activateEditOnProcess: () => {
            this.onEditProcess = true;
          },
          updateMap: async () => {
            this.$notify({
              group: "zones",
              clean: true,
            });
          },
          removeShape: async () => {
            this.toBeSaveShapeFSAID = null;
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
            await this.closeSelectableFSAMode();
          },
          cancelEdit: async () => {
            await this.closeSelectableFSAMode();
          },
        },
      });
      // .addTo(this.map)
      instance.$mount();
      const contentPopup = instance.$el;
      const popupContent = window.L.popup({
        autoPan: false,
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
      }).setContent(contentPopup);
      polygon.bindPopup(popupContent).openPopup();
      this.toBeSaveShapeFSAID = polygon._leaflet_id;
    },
    async refresh() {
      this.refreshLoading = true;
      await this.renderZonesToMap(false, this.$store.state.region);
      this.refreshLoading = false;
    },
    errorNotificatation() {
      this.$notify({
        group: "zones",
        clean: true,
      });
      this.$notify({
        type: "error",
        group: "zones",
        title: "Shape is not saved",
        text: "The shape is not a valid shape",
      });
      this.closeAddDeletePartShapeMode();
    },
  },
  computed: {
    ...mapState(["region"]),
  },
  async mounted() {
    await this.$store.dispatch("onLoad");
    await this.$store.dispatch("initialize");
    const currentEnvironment = this.$router.currentRoute.params.pathMatch;
    const viewId = this.$route.query.id;
    console.log("setting environment", currentEnvironment, this.$route);
    if (!currentEnvironment) {
      this.$router.push("/environments?id=" + viewId);
      return;
    }
    console.log("view", currentEnvironment);
    this.$store.dispatch("setEnvironment", currentEnvironment);
    this.showChangingRegionModal = true;
    this.mappingLoaded = true;
    this.map = this.$refs.map.mapObject;
    this.spiderfy = new OverlappingMarkerSpiderfier(this.map, {
      nearbyDistance: 5,
    });
    // add listener to prevent popup from showing when click the one of the overlapping markers
    this.spiderfy.addListener("spiderfy", () => {
      this.map.closePopup();
    });
    window.map = this.map;
    const drawnItems = new window.L.FeatureGroup();
    this.drawnItems = drawnItems;
    const FSAItems = new window.L.FeatureGroup();
    this.FSAItems = FSAItems;
    const drawnMarkers = new window.L.FeatureGroup();
    const drawnMarkersPostalCodes = new window.L.FeatureGroup();
    const markerGroup = window.L.markerClusterGroup({
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
      chunkedLoading: true,
      animate: false,
      maxClusterRadius: 100,
      iconCreateFunction: () => {
        return window.L.divIcon({ html: "", className: "marker-cluster-icon" });
      },
    });
    const markerGroupFSA = window.L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      chunkedLoading: true,
      animate: true,
      maxClusterRadius: 50,
    });
    this.markerGroup = markerGroup;
    this.markerGroupFSA = markerGroupFSA;
    this.postalCodesGroup = drawnMarkersPostalCodes;
    // Define you draw handler somewhere where you click handler can access it. N.B. pass any draw options into the handler
    this.map.addLayer(this.drawnItems);
    this.map.addLayer(drawnMarkers);
    this.map.addLayer(drawnMarkersPostalCodes);
    this.map.addLayer(markerGroup);
    // this.map.addLayer(markerGroupFSA)
    // toolbar for polygon
    window.L.EditToolbar.Delete.include({
      removeAllLayers: false,
    });
    const drawControl = new window.L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        edit: false,
      },
      draw: {
        polyline: true,
        polygon: {
          shapeOptions: {
            clickable: true,
            color: "#00D1B2",
          },
          allowIntersection: false,
        },
        rectangle: {
          shapeOptions: {
            clickable: true,
            color: "#00D1B2",
          },
        },
        marker: {
          shapeOptions: {
            clickable: true,
            color: "#6169e0",
          },
        },
        circlemarker: false,
      },
      position: "topright",
    });
    const provider = new GoogleProvider({ apiKey: 'AIzaSyD8O0Tq7WeY3zAQUl3crewR54CVBkVEq9A' });

    const searchControl =
      new SearchControl({
        style: 'button',
        provider,
        notFoundMessage: 'No results were found',
      });

    this.map.addControl(searchControl);
    const results = window.L.layerGroup().addTo(this.map);
    this.drawControl = drawControl;
    // on select shape
    this.drawnItems.on("click", (e) => {
      const selectedShapeId = e.sourceTarget._leaflet_id;
      const selectedShape = this.drawnZones.find(
        (d) => d.leafletId === selectedShapeId
      );
      if (
        selectedShape &&
        !this.editShapeSelectedMode &&
        !this.addMoreShapeMode &&
        !this.deletePartShapeMode &&
        !this.selectableFSAMode &&
        !this.editSelectableFSAMode
      ) {
        this.selectedShape = selectedShape;
      }
    });
    try {
      // const sharedView = await sharedViewsCollection.where('urlId', '==', this.$route.query.id).get()
      // const allowedRegion = sharedView.docs[0].data().region
      regionsCollection
        .orderBy("name")
        .get()
        .then(async (snapshot) => {
          const regionsData = await snapshot.docs.map((doc) => ({
            ...doc.data(),
          }));
          await this.$store.dispatch("setRegions", regionsData);
          this.regions = regionsData;
          if (!this.initialLoadRegion) {
            if (this.regions.length > 0) {
              this.showRegionList = false;
              this.center = regionCenter(this.regions[0].name);
              this.$store.dispatch("setRegion", this.regions[0].id);
              this.renderZonesToMap(true, this.regions[0].id);
              this.initialLoadRegion = true;
            } else {
              this.showRegionList = true;
              this.initialLoadRegion = true;
            }
          }
          if (regionsData.length <= 0) {
            this.showRegionList = true;
          }
        });
      zonesInfoCollection
        .where("environment", "==", this.$store.state.environment.toLowerCase())
        .onSnapshot((snapshot) => {
          const zoneInformations = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          this.$store.dispatch("setZoneInformations", zoneInformations);
        });
    } catch (error) {
      console.log("error:", error);
    }

    // await this.renderFSAToMap("AB");
    const mongoAppForShape = await mongoDbAppForShape();
    const zonesCollection = mongoAppForShape.collection("zones");
    for await (const change of zonesCollection.watch()) {
      switch (change.operationType) {
        case "insert":
        case "update":
        case "replace":
        case "delete": {
          this.$notify({
            group: "zones",
            clean: true,
          });
          await this.renderZonesToMap(false, this.$store.state.region);
        }
      }
    }
  },
};
</script>
<style lang="scss">
@import "../assets/theme/color_variables";
/* FSA name layer */
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css";
.leaflet-control-geosearch.active form {
  display: flex;
  flex-wrap: wrap;
  min-width: 250px;
}

.leaflet-control-geosearch.active .glass {
  flex: 1;
}

.leaflet-control-geosearch.active .reset {
  border: none;
  background: none;
}

.leaflet-control-geosearch .results {
  width: 100%;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results .leaflet-bar-notfound {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}
/* customizing the circles for FSA name layer */
.marker-cluster > * {
  opacity: 0.2 !important;
  background-color: $success !important;
  color: white !important;
}
.leaflet-popup-content-wrapper {
  width: 53vw;
}
.FSA-Content > .leaflet-popup-content-wrapper {
  width: 8vw;
}
.leaflet-popup-content {
  width: 90% !important;
}
.FSA_tooltip {
  /* background: transparent; */
  border: none;
  font-weight: 600;
  font-style: italic;
}
/* esri search control */
.geocoder-control-input {
  border: 2px solid $success !important;
  font-family: "Poppins", sans-serif !important;
}
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-draw-draw-polygon,
.leaflet-draw-draw-rectangle,
.leaflet-draw-draw-circle,
.leaflet-draw-draw-marker,
.leaflet-draw-section,
.leaflet-control-layers,
.leaflet-control-layers-expanded {
  color: $success !important;
}
.navbar-item {
  color: $success !important;
}
</style>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
.leaflet-control-layers-overlays {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
