<template>
  <div>
    <!-------------------------- MAP ---------------------------->
    <l-map
      ref="map"
      v-bind:style="mapStyling"
      :zoom="zoom"
      :maxZoom="maxZoom"
      :center="center"
      :options="mapOptions"
      @update:zoom="zoomUpdated"
      @update:bounds="boundsUpdated"
    >
      <l-control position="topright" id="v-step-0">
        <button
          data-tooltip="Left tooltip content"
          v-bind:class="
            refreshLoading
              ? 'button is-small is-loading is-success is-outlined is-rounded is-inverted'
              : 'button is-small is-success is-outlined is-rounded is-inverted is-hovered'
          "
          @click="refresh"
        >
          <span class="icon">
            <i class="fas fa-redo has-text-success"></i>
          </span>
        </button>
      </l-control>
      <l-control-zoom
        position="topright"
        class="leaflet-control-zoom"
      ></l-control-zoom>
      <l-tile-layer :url="urlOsrmBright"></l-tile-layer>
      <l-control position="bottomright">
        <QueuePopUp
          :showQueuePopUp="showQueuePopUp"
          :queue="queue"
          :currentAction="currentAction"
          :busy="busy"
          @removeActionInQueue="removeActionInQueue"
          @closeModal="showQueuePopUp = false"
          v-if="mappingLoaded"
        />
      </l-control>
    </l-map>
    <!--------------------------  MAP END ----------------------------->
    <!--------------------------  TOOLBAR START ----------------------->
    <div
      style="
        position: fixed;
        bottom: 0;
        z-index: 1000;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.8);
      "
      class="is-centered is-mobile has-text-centere py-3"
    >
      <div class="select is-small is-success mx-2">
        <select
          style="width: 220px"
          id="v-step-1"
          @change="selectRegion"
          :disabled="retrievingFSA"
        >
          <option
            v-for="option in regions"
            :selected="option.id == $store.state.region"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
      <!-- <button
        class="button is-small is-success mx-2 is-outlined"
        @click="showHistoryModal = true"
      >
        History
      </button> -->
      <button
        v-if="
          selectableFSAMode &&
          selectedFSAToAdd.length > 0 &&
          !editSelectableFSAMode
        "
        class="button is-small is-success mx-2 is-outlined"
        @click="openAddFSAShapeZoneModal"
      >
        Confirm FSA Zones
      </button>
      <button
        v-if="
          selectableFSAMode &&
          selectedFSAToAdd.length > 0 &&
          editSelectableFSAMode
        "
        class="button is-small is-success mx-2 is-outlined"
        @click="openEditFSAShapeZoneModal"
      >
        Save FSA Zones
      </button>
      <button
        v-if="deletePartShapeMode"
        class="button is-small is-success mx-2 is-outlined"
        @click="closeAddDeletePartShapeMode()"
      >
        Close Drawing Mode
      </button>
      <button
        class="is-small is-success button mx-2"
        role="navigation"
        aria-label="dropdown navigation"
      >
        <div class="navbar-item has-dropdown-up is-hoverable is-small">
          <button class="is-small is-success button" id="v-step-3">Menu</button>
          <div class="navbar-dropdown">
            <a
              class="navbar-item"
              @click="showExportModal = true"
              v-if="!retrievingFSA"
            >
              Export Data
            </a>
            <a
              class="navbar-item"
              @click="showExportServiceModal = true"
              v-if="!retrievingFSA"
            >
              Export For Service
            </a>
            <a
              v-if="client === 'miele' && !retrievingFSA"
              class="navbar-item"
              @click="showExportServiceModal = true"
            >
              Export For Miele Professionals
            </a>
            <a class="navbar-item" @click="showImportModal = true">
              Import Data
            </a>
            <hr class="navbar-divider" />
            <a
              class="navbar-item"
              @click="showSharedViews = true"
              v-if="
                !selectableFSAMode && !editSelectableFSAMode && !FSAConfirmMode
              "
            >
              Shared Views
            </a>
            <a
              class="navbar-item"
              @click="showRegionList = true"
              v-if="
                !selectableFSAMode && !editSelectableFSAMode && !FSAConfirmMode
              "
            >
              View Regions
            </a>
            <a
              class="navbar-item"
              @click="showZoneListModal = true"
              v-if="
                !selectableFSAMode && !editSelectableFSAMode && !FSAConfirmMode
              "
            >
              View Zones
            </a>
            <a
              class="navbar-item"
              @click="showRouteHubModal = !showRouteHubModal"
            >
              View Time/Routing Table
            </a>
            <hr class="navbar-divider" />
            <a
              class="navbar-item"
              v-if="
                !addMoreShapeMode && !deletePartShapeMode && !FSAConfirmMode
              "
              @click="
                selectableFSAMode
                  ? closeSelectableFSAMode(false)
                  : selectableFSAModeOn(false)
              "
            >
              Toggle FSAs visibility
            </a>
            <a class="navbar-item" @click="showFSASelector = true">
              Switch FSA Province
            </a>
            <hr class="navbar-divider" />
            <a class="navbar-item" @click="$router.push('/home')">
              Toggle Environment
            </a>
            <hr class="navbar-divider" />
            <a class="navbar-item" @click="logout"> Logout </a>
          </div>
        </div>
      </button>
    </div>
    <!--------------------------  TOOLBAR - end ---------------------------->
    <notifications group="fsa" position="bottom left" />
    <notifications
      group="export"
      position="top left"
      class="export_notification"
    />
    <notifications group="zones" position="bottom right" />
    <notifications
      group="postal_code_search"
      position="top right"
      class="postal-code-search"
    />
    <ZoneList
      @renderMap="renderZonesToMap(false, $store.state.region)"
      :showZoneListModal="showZoneListModal"
      @closeModal="showZoneListModal = false"
      v-if="mappingLoaded && showZoneListModal"
    />
    <SharedViews
      :showSharedViews="showSharedViews"
      :regions="regions"
      @closeModal="showSharedViews = false"
      v-if="mappingLoaded && showSharedViews"
    />
    <RegionList
      @renderMap="renderZonesToMap(false, $store.state.region)"
      :showRegionList="showRegionList"
      @closeModal="showRegionList = false"
      v-if="mappingLoaded && showRegionList"
    />
    <ChangeRegionModal
      :showChangingRegionModal="showChangingRegionModal"
      @closeModal="showChangingRegionModal = false"
      v-if="mappingLoaded && showChangingRegionModal"
    />
    <History
      :showHistory="showHistoryModal"
      @closeModal="showHistoryModal = false"
      v-if="mappingLoaded && showHistoryModal"
    />
    <HubRouteTable
      :showRouteHubModal="showRouteHubModal"
      @closeModal="showRouteHubModal = false"
      v-if="mappingLoaded && showRouteHubModal"
    />
    <FSASelector
      :showFSASelector="showFSASelector"
      :retrievingFSA="retrievingFSA"
      @closeModal="showFSASelector = false"
      @selectFSAProvince="selectFSAProvince"
      @closeSelectableFSAMode="closeSelectableFSAMode"
      v-if="mappingLoaded && showFSASelector"
    />
    <ExportModal
      :showExportModal="showExportModal"
      @closeModal="showExportModal = false"
      v-if="mappingLoaded && showExportModal"
    />
    <ExportServiceModal
      :showExportServiceModal="showExportServiceModal"
      @closeModal="showExportServiceModal = false"
      v-if="mappingLoaded && showExportServiceModal"
    />
    <ImportModal
      :showImportModal="showImportModal"
      @closeModal="showImportModal = false"
      v-if="mappingLoaded && showImportModal"
    />
    <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
  </div>
</template>
<script>
import Vue from "vue";
import { LMap, LTileLayer, LControlZoom, LControl } from "vue2-leaflet";
import { GoogleProvider, SearchControl } from 'leaflet-geosearch';
import { mapState } from "vuex";
import centerMass from "@turf/center-of-mass";
import { polygon as polygonTurf, circle as circleTurf } from "@turf/turf";
import AddShapeModal from "@/components/AddShapeModal.vue";
import AddMarkerZoneModal from "@/components/AddMarkerZoneModal.vue";
import EditMarkerZoneModal from "@/components/EditMarkerZoneModal.vue";
import AddFSAZoneModal from "@/components/AddFSAZoneModal.vue";
import EditFSAZoneModal from "@/components/EditFSAZoneModal.vue";
import EditShape from "../utils/components/EditShape";
import {
  hubsCollection,
  zonesInfoCollection,
  regionsCollection,
  configurationsCollection,
  vehiclesCollection,
  sharedViewsCollection,
  vendorsCollection,
  auth,
} from "../store/fireStore";
import {
  getZones,
  insertZone,
  deleteZone,
  getFSA,
  getPostalCode,
  EditShapeZone,
  mongoDbAppForShape,
  updatePostalCode,
  editZoneCoordinatesMultiple,
} from "../store/mongoStore";
import { ObjectId } from "bson";
import { regionCenter, geoJsonFeature } from "./utils";
import * as leafletPip from "@mapbox/leaflet-pip";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-toolbar";
import "leaflet.markercluster";
import streamSaver from "streamsaver";
import WorkerAddMoreShape from "worker-loader!../workers/worker_addMoreShape.js";
import WorkerDeletePartShapeMode from "worker-loader!../workers/worker_deletePartShapeMode.js";
import WorkerAddMoreShapeOverlapping from "worker-loader!../workers/worker_addMoreShapeOverlapping.js";
import WorkerAddShape from "worker-loader!../workers/worker_addShape.js";
import WorkerSelectFSA from "worker-loader!../workers/worker_selectFSA.js";
import WorkerCombineSelectedFSA from "worker-loader!../workers/worker_combineSelectedFSA.js";
import WorkerExportPostalCode from "worker-loader!../workers/worker_exportPostalCode.js";
import "overlapping-marker-spiderfier-leaflet/dist/oms";
const OverlappingMarkerSpiderfier = window.OverlappingMarkerSpiderfier;
/* leaflet default _getIconUrl has a bug and so the fix is temp here */
delete window.L.Icon.Default.prototype._getIconUrl;
window.L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
let workerAddMoreShape;
let workerDeletePartShapeMode;
let workerAddMoreShapeOverlapping;
let workerAddShape;
let workerSelectFSA;
let workerCombineSelectedFSA;
let workerExportPostalCode;
if (window.Worker) {
  workerAddMoreShape = new WorkerAddMoreShape();
  workerDeletePartShapeMode = new WorkerDeletePartShapeMode();
  workerAddMoreShapeOverlapping = new WorkerAddMoreShapeOverlapping();
  workerAddShape = new WorkerAddShape();
  workerSelectFSA = new WorkerSelectFSA();
  workerExportPostalCode = new WorkerExportPostalCode();
  workerCombineSelectedFSA = new WorkerCombineSelectedFSA();
}

export default {
  name: "Map",
  components: {
    FSASelector: () => import("@/components/FSASelector.vue"),
    ZoneList: () => import("@/components/Zone/ZoneListModal.vue"),
    RegionList: () => import("@/components/Region/RegionListModal.vue"),
    SharedViews: () => import("@/components/SharedViews/SharedViews.vue"),
    ChangeRegionModal: () => import("@/components/ChangeRegionModal"),
    History: () => import("@/components/History"),
    QueuePopUp: () => import("@/components/QueuePopUp"),
    HubRouteTable: () => import("@/components/HubRouteTable"),
    ExportModal: () => import("@/components/ExportModal"),
    ExportServiceModal: () => import("@/components/ExportServiceModal"),
    ImportModal: () => import("@/components/ImportModal"),
    LMap,
    LTileLayer,
    LControlZoom,
    LControl,
  },
  data() {
    return {
      client: process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID,
      distzoneListenerUnsubscribe: null,
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          header: {
            title: "1 min tour",
          },
          content: `Refresh the map here.`,
        },
        {
          target: "#v-step-1",
          content: "Main regions of your business.",
        },
        {
          target: "#v-step-2",
          content: "Your actions queue up here.",
        },
        {
          target: "#v-step-3",
          content: "Explore the menu to see more options.",
        },
      ],
      spiderfy: null,
      mappingLoaded: false,
      importingLoading: false,
      refreshLoading: false,
      showZoneListModal: false,
      showChangingRegionModal: false,
      showHistoryModal: false,
      showQueuePopUp: false,
      searchLoading: false,
      showRouteHubModal: false,
      showFSASelector: false,
      showSharedViews: false,
      showRegionList: false,
      showImportModal: false,
      showExportModal: false,
      showExportServiceModal: false,
      zoom: 10,
      maxZoom: 20,
      center: [45.203427, -76.016414],
      bounds: null,
      map: null,
      postalCodeSearch: "",
      drawnZones: [],
      drawnMarkers: [],
      drawnMarkersPostalCodes: [],
      drawnFSA: [],
      zones: [],
      markers: [],
      regions: [],
      selectedFSAProvince: "AB",
      editedZones: [],
      vehicles: [],
      vehicleObj: {},
      drawControl: null,
      markerGroup: null,
      markerGroupFSA: null,
      addDeletePartShapeModeDrawControl: null,
      FSALayerGroup: null,
      FSANameLayerGroup: null,
      FSALayerControl: null,
      retrievingFSA: false,
      drawnItems: null,
      FSAItems: null,
      postalCodesGroup: null,
      storeMarkers: window.L.layerGroup(),
      supplierMarkers: window.L.layerGroup(),
      primaryMarkers: window.L.layerGroup(),
      secondaryMarkers: window.L.layerGroup(),
      stemMarkers: window.L.layerGroup(),

      selectedFSAToAdd: [],
      toolbarStyling: {
        width: "0vw",
      },
      mapStyling: {
        width: "100vw",
      },
      mapContainerStyling: {
        position: "initial",
      },
      mapOptions: {
        zoomControl: false,
        // preferCanvas: true
      },
      selectedShape: null,
      currentSelectedFSAShape: [],
      toBeSaveShapeFSAID: null,
      editShapeSelectedMode: false,
      addMoreShapeMode: false,
      deletePartShapeMode: false,
      selectableFSAMode: false,
      editSelectableFSAMode: false,
      FSAConfirmMode: false,
      postalCodeMarkerShow: false,
      initialLoadRegion: false,
      queue: [],
      busy: false,
      onEditProcess: false,
      currentAction: null,
      urlSmooth: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${process.env.VUE_APP_STADIA_API}`,
      urlSmoothDark: `https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=${process.env.VUE_APP_STADIA_API}`,
      urlOutdoors: `https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=${process.env.VUE_APP_STADIA_API}`,
      urlOsrmBright: `https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=${process.env.VUE_APP_STADIA_API}`,
    };
  },
  created() {
    window.addEventListener("beforeunload", (e) => {
      if (this.queue.length > 0 && !this.busy) {
        const confirmationMessage =
          "Queue is in progress. You may lose some of your work if you exit now. Please cancel and remain on the page till your work is saved.";
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });
  },
  watch: {
    region: {
      handler(newVal, oldVal) {
        if (!newVal || !this.regions || !this.regions.length) return;
        if (this.distzoneListenerUnsubscribe) {
          this.distzoneListenerUnsubscribe();
        }
        this.$store.dispatch(
          "setSelectedVehicles",
          this.$store.state.vehicles[newVal] || []
        );
        // initial get all zones
        const drawnMarkers = new window.L.FeatureGroup();
        try {
          this.distzoneListenerUnsubscribe = hubsCollection
            .where("Region", "==", `${newVal}`)
            .onSnapshot((snapshot) => {
              const drawnHubs = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              this.$store.dispatch("setHubs", drawnHubs);
              this.markers = drawnHubs.filter(
                (shape) => shape.regionHub === this.$store.state.region
              );
              this.drawnMarkers
                .map((d) => d.leafletId)
                .forEach((id) => {
                  this.map.removeLayer(this.map._layers[id]);
                });
              this.drawnMarkers = [];
              for (const x in drawnMarkers._layers) {
                drawnMarkers.removeLayer(drawnMarkers._layers[x]);
              }
              drawnHubs.map((DistributionZone) => {
                const coordinates = {
                  lat: DistributionZone.Center.latitude,
                  lng: DistributionZone.Center.longitude,
                };
                const color = DistributionZone.Color;
                /* 0, 1, 2: MAIN, SUB, STORE */
                const distributionZoneType = this.$store.getters.DistributionZoneTypes.find(type => type.value === DistributionZone.DistributionZoneType)
                const DistributionZoneId = DistributionZone.DistributionZoneId;

                let timeZone = DistributionZone.TimeZone || ""
                const gjLayer = window.L.geoJson(JSON.parse(geoJsonFeature));

                if (!timeZone) {
                  const firstPointResult = leafletPip.pointInLayer([coordinates.lng, coordinates.lat], gjLayer);
                  timeZone = firstPointResult[0].feature.properties.tz_name1st || ""
                }

                const marker = new window.L.Marker(coordinates, {
                  icon: this.getIcon(color, distributionZoneType.label),
                  riseOnHover: true,
                  draggable: true,
                }).addTo(this.map);
                this.addMarkerToLayerGroup(marker, distributionZoneType.label);
                //   const zone = shape.zone
                const ComponentClass = Vue.extend(EditMarkerZoneModal);
                const instance = new ComponentClass({
                  propsData: {
                    store: this.$store,
                    map: this.map,
                    DistributionZone: JSON.parse(
                      JSON.stringify(DistributionZone)
                    ),
                    editable: true,
                    coordinates: coordinates,
                    region: this.$store.state.region,
                    timeZone: timeZone
                  },
                  methods: {
                    close: () => {
                      if (this.map._layers[marker._leaflet_id]) {
                        this.map.removeLayer(
                          this.map._layers[marker._leaflet_id]
                        );
                      }
                      this.map.closePopup();
                    },
                  },
                });
                instance.$mount();

                const contentPopup = instance.$el;
                const markerProperties = {
                  leafletId: marker._leaflet_id,
                  coordinates,
                  id: DistributionZoneId,
                  region: DistributionZone.regionHub,
                  timeZone: timeZone,
                };
                this.drawnMarkers.push(markerProperties);
                marker.bindPopup(contentPopup);

                marker.on("dragstart", () => {
                  this.map.closePopup();
                });

                marker.on("dragend", (e) => {
                  const { lat, lng } = e.target._latlng;

                  const dragendPointResult = leafletPip.pointInLayer([lng, lat], gjLayer);
                  timeZone = dragendPointResult[0].feature.properties.tz_name1st || ""
                  instance.timeZone = timeZone
                  instance.coordinates = { lat, lng };
                  instance.$mount();
                  const _contentPopup = instance.$el;
                  marker.setPopupContent(_contentPopup);
                  marker.openPopup();
                });

                drawnMarkers.addLayer(marker);
                // add marker to oms instance
                this.spiderfy.addMarker(marker);
              });
              console.log(this.regions.length);
              this.center = regionCenter(
                this.regions.filter((r) => r.id === newVal)[0].name
              );
            });
        } catch (error) {
          console.log("error", error);
        }
      },
    },
    queue: {
      async handler(newVal, oldVal) {
        console.log("queue >>> watch handler processQueue()", oldVal, newVal);
        if (newVal && newVal.length > 0 && !this.busy) {
          await this.processQueue();
        }
      },
    },
  },
  methods: {
    async processQueue() {
      console.log("queue >>> processQueue()");
      if (this.queue.length) {
        this.busy = true;
        const currentQueueItem = this.queue.shift();
        const { action, state } = currentQueueItem;
        this.currentAction = action;
        await this.processQueueHandler(action, state);
      } else {
        this.busy = false;
        this.currentAction = null;
      }
    },
    async processQueueHandler(action, state) {
      console.log("queue >>> processQueueHandler()", action, state);
      await this.processHandler(action, state);
      return setTimeout(() => {
        this.busy = false;
        this.processQueue();
        return Promise.resolve();
      }, 100);
    },
    async processHandler(action, state) {
      if (action === "INSERT_NEW_SHAPE") {
        const { coordinates, shapesID, multi, fsa } = state;
        const newZone = await insertZone(coordinates, shapesID, multi, fsa);
        this.$notify({ group: "zones", clean: true });
        this.$notify({
          group: "zones",
          title: "Processing",
          closeOnClick: false,
          duration: 5000,
          text: "Data processing, please wait...",
        });
        const {
          coordinates: newCoordinates,
          shapesID: newShapesID,
          multi: newMulti,
        } = newZone;
        console.log("check new zone,", newZone);
        const newShape = await updatePostalCode(
          newCoordinates,
          newShapesID,
          newMulti
        );
        return Promise.resolve(newShape);
      } else if (action === "DELETE_SHAPE") {
        const { shapeId } = state;
        const { coordinates, isMulti, newZone } = await deleteZone(shapeId);
        const updatedPostalCode = await updatePostalCode(
          coordinates,
          newZone,
          isMulti
        );
        return Promise.resolve(updatedPostalCode);
      } else if (action === "INSERT_NEW_SHAPE_FROM_EDIT_OVERLAPPING") {
        const { coordinates, zoneId, multi, fsa } = state;
        const {
          coordinates: newCoordinates,
          isMulti,
          newZone,
        } = await insertZone(coordinates, zoneId, multi, fsa);
        const updatedPostalCodes = await updatePostalCode(
          newCoordinates,
          newZone,
          isMulti
        );
        return Promise.resolve(updatedPostalCodes);
      } else if (action === "EDIT_COORDINATES") {
        const updatedShape = await this.updateShape("EDIT_COORDINATES", state);
        return Promise.resolve(updatedShape);
      } else if (action === "PROCESS_EDITING_ADD") {
        const { selectedShape, coordinates, drawnZones } = state;
        const mongoAppForShape = await mongoDbAppForShape();
        const zonesCollection = mongoAppForShape.collection("zones");
        const currentSelectedShape = await zonesCollection.findOne({
          _id: ObjectId(selectedShape.id),
        });
        const currentSelectedShapeCoordinates = currentSelectedShape.multi
          ? JSON.parse(currentSelectedShape.coordinates).map((shape) => {
              return shape.map((c) => [c[1], c[0]]);
            })
          : JSON.parse(currentSelectedShape.coordinates).map((c) => [
              c[1],
              c[0],
            ]);
        const currentSelectedShapeInvertedOrderCoordinates = JSON.parse(
          currentSelectedShape.coordinates
        );
        const selectedShapeUpdated = {
          ...currentSelectedShape,
          coordinates: currentSelectedShapeCoordinates,
          invertedCoordinates: currentSelectedShapeInvertedOrderCoordinates,
          shapeId: currentSelectedShape._id.toString(),
          id: currentSelectedShape._id.toString(),
        };
        workerAddMoreShape.postMessage({
          selectedShape: selectedShapeUpdated,
          coordinates,
          drawnZones,
          drawnFSA: this.drawnFSA,
        });
        return Promise.resolve();
      } else if (action === "PROCESS_EDITING_REMOVE") {
        const { selectedShape, coordinates, drawnZones } = state;
        const mongoAppForShape = await mongoDbAppForShape();
        const zonesCollection = mongoAppForShape.collection("zones");
        const currentSelectedShape = await zonesCollection.findOne({
          _id: ObjectId(selectedShape.id),
        });
        const currentSelectedShapeCoordinates = currentSelectedShape.multi
          ? JSON.parse(currentSelectedShape.coordinates).map((shape) => {
              return shape.map((c) => [c[1], c[0]]);
            })
          : JSON.parse(currentSelectedShape.coordinates).map((c) => [
              c[1],
              c[0],
            ]);
        const currentSelectedShapeInvertedOrderCoordinates = JSON.parse(
          currentSelectedShape.coordinates
        );
        const selectedShapeUpdated = {
          ...currentSelectedShape,
          coordinates: currentSelectedShapeCoordinates,
          invertedCoordinates: currentSelectedShapeInvertedOrderCoordinates,
          shapeId: currentSelectedShape._id.toString(),
          id: currentSelectedShape._id.toString(),
        };
        workerDeletePartShapeMode.postMessage({
          selectedShape: selectedShapeUpdated,
          coordinates,
          drawnFSA: this.drawnFSA,
        });
        return Promise.resolve();
      } else if (action === "EDIT_MULTIPLE_ZONE_FSA") {
        const { newShapes, currentShape, shapeType } = state;
        this.onEditProcess = true;
        await editZoneCoordinatesMultiple(
          newShapes,
          currentShape,
          shapeType
        ).then((res) => {
          this.onEditProcess = false;
          return Promise.resolve();
        });
      }
    },
    async updateShape(action, state) {
      const { coordinates, shapeId, multi, fsa } = state;
      const res = await EditShapeZone({
        coordinates,
        shapeId,
        multi,
        fsa,
      });
      this.onEditProcess = false;
      const {
        currentZoneCoordinates,
        currentZoneMulti,
        newZoneCoordinates,
        newShapesId,
        newMulti,
      } = res;
      const firstUpdate = await updatePostalCode(
        currentZoneCoordinates,
        "",
        currentZoneMulti
      );
      const lastUpdate = await updatePostalCode(
        newZoneCoordinates,
        newShapesId,
        newMulti
      );
      return { lastUpdate, firstUpdate };
      // return { ...res };
    },
    /* Select the province for which to load the FSAs */
    async selectFSAProvince(selectedFSAProvince) {
      if (this.selectedFSAProvince === "") {
        this.$notify({
          type: "warn",
          group: "export",
          title: "No selected provice",
          text: "Please select a region",
          duration: 20000,
        });
        return;
      }
      this.showChangingRegionModal = true;
      this.closeSelectableFSAMode(true);
      this.center = regionCenter(selectedFSAProvince);
      this.map.removeLayer(this.FSALayerGroup);
      this.map.removeLayer(this.FSANameLayerGroup);
      this.map.removeControl(this.FSALayerControl);
      this.renderFSAToMap(selectedFSAProvince);
      this.showFSASelector = false;
    },
    /* Update map zoon */
    zoomUpdated(zoom) {
      this.zoom = zoom;
      this.mapContainerStyling = {
        position: "initial",
      };
    },
    /* Update map bounds */
    boundsUpdated(bounds) {
      this.bounds = bounds;
      this.mapContainerStyling = {
        position: "initial",
      };
    },
    /**
     * @param  {e} event - value of selected region
     */
    async selectRegion(e) {
      const newRegion = e.target.value;
      const selectedFleet = this.$store.state.vehicles[newRegion] || [];
      this.vehicles = selectedFleet;
      this.$store.dispatch("setRegion", newRegion);
      this.$store.dispatch("setSelectedVehicles", selectedFleet);
      this.$store.dispatch("setTrucksToBeAssigned", []);
      this.zones = this.$store.state.zoneInformations.filter(
        (doc) => doc.environment === this.$store.state.environment
      );
      // this is to avoid getting the fsa of the same province
      setTimeout(async () => {
        await this.renderZonesToMap(true, newRegion);
      }, 500);
    },
    /**
     * Search Postal Code
     */
    async searchPostalCode() {
      // const currentRegion = this.$store.state.region
      this.searchLoading = true;
      try {
        this.postalCodesGroup.clearLayers();
        const postalCodeSearch = this.postalCodeSearch
          .trim()
          .replace(/\s/g, "");
        const result = await getPostalCode(postalCodeSearch);
        if (result.length > 0) {
          console.log("result:", result);
          const [long, lat] = result[0].geometry.coordinates;
          console.log("long:", long);
          console.log("lat:", lat);
          result.forEach((postalCode) => {
            const coordinates = [
              postalCode.geometry.coordinates[1],
              postalCode.geometry.coordinates[0],
            ];
            console.log(coordinates);
            const marker = new window.L.Marker(coordinates, {
              icon: this.getIcon("yellow"),
              riseOnHover: true,
            });
            this.postalCodesGroup.addLayer(marker);
          });
          this.postalCodeMarkerShow = true;
          this.$notify({
            group: "postal_code_search",
            title: "postal code exist",
            closeOnClick: true,
            duration: 3000,
            text: "The postal code you tried to find exists",
          });
          this.center = [lat, long];
        } else {
          this.$notify({
            group: "postal_code_search",
            title: `Cannot find postal code ${postalCodeSearch}`,
            closeOnClick: true,
            duration: 3000,
            text: "The postal code you tried to find does not exist",
          });
        }
      } catch (err) {
        this.$notify({
          group: "postal_code_search",
          title: "Error on finding postal code",
          closeOnClick: true,
          duration: 3000,
          text: "Please try again",
        });
      }
      this.searchLoading = false;
    },
    /**
     * Return Icon indicator for DISTRIBUTIONZONES
     */
    getIcon(color, type) {
      let svgString;
      if (type === "STORE") {
        svgString = `<svg style="fill:${color}" width="20px" height="20px" viewBox="0 0 20 20"><path d="M13 17a2 2 0 1 0 3.999.001A2 2 0 0 0 13 17zM3 17a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm3.547-4.828L17.615 9.01A.564.564 0 0 0 18 8.5V3H4V1.4c0-.22-.181-.4-.399-.4H.399A.401.401 0 0 0 0 1.4V3h2l1.91 8.957.09.943v1.649c0 .219.18.4.4.4h13.2c.22 0 .4-.182.4-.4V13H6.752c-1.15 0-1.174-.551-.205-.828z"></path></svg>`;
      } else if (type === "SUPPLIER") {
        svgString = `<svg fill="${color}" width="25px" height="25px" viewBox="0 0 33 32"><path d="M32.45,8.44,22,15.3V9.51a1,1,0,0,0-1.63-.78L14.07,14H10V4.06L4,2.71V14H2V31a1,1,0,0,0,1,1H33a1,1,0,0,0,1-1V9.27A1,1,0,0,0,32.45,8.44ZM14,29H6V27h8Zm0-4H6V23h8Zm0-4H6V19h8Zm8,8H20V26h2Zm0-6H20V20h2Zm4,6H24V26h2Zm0-6H24V20h2Zm4,6H28V26h2Zm0-6H28V20h2Z"></path></svg>`;
      } else {
        svgString = `<svg version="1.1" viewBox="0 0 20 34" height="45" width="45" enable-background="new 0 0 18 18" style="fill:${
          color || "black"
        }"><g><polygon points="16,5 9,1 2,5 2,17 4,17 4,7 14,7 14,17 16,17"></polygon><rect x="5" y="15" width="2" height="2"></rect><rect x="8" y="15" width="2" height="2"></rect><rect x="11" y="15" width="2" height="2"></rect><rect x="5" y="12" width="2" height="2"></rect><rect x="8" y="12" width="2" height="2"></rect><rect x="11" y="12" width="2" height="2"></rect><rect x="5" y="9" width="2" height="2"></rect><rect x="8" y="9" width="2" height="2"></rect></g></svg>`;
      }
      return window.L.divIcon({
        className: "my-custom-pin",
        html: svgString,
      });
    },
    /* adding markers to their own type layer so they can be filtered */
    addMarkerToLayerGroup(marker, type) {
      if (!this.storeMarkers) this.storeMarkers = window.L.layerGroup();
      if (!this.supplierMarkers) this.supplierMarkers = window.L.layerGroup();
      if (!this.primaryMarkers) this.primaryMarkers = window.L.layerGroup();
      if (!this.secondaryMarkers) this.secondaryMarkers = window.L.layerGroup();
      if (!this.stemMarkers) this.stemMarkers = window.L.layerGroup();

      if (type === "STORE" || type === 3) {
        this.storeMarkers.addLayer(marker);
      } else if (type === "SUPPLIER" || type === 4) {
        this.supplierMarkers.addLayer(marker);
      } else if (type === "PRIMARY" || type === 0) {
        this.primaryMarkers.addLayer(marker);
      } else if (type === "SECONDARY" || type === 1) {
        this.secondaryMarkers.addLayer(marker);
      } else if (type === "STEM" || type === 2) {
        this.stemMarkers.addLayer(marker);
      }
    },
    /**
     * If the app is on edit mode. It reverts back to default mode
     */
    closeEditCurrentShapeMode() {
      this.editShapeSelectedMode = false;
      this.selectedShape = null;
      this.map.addControl(this.drawControl);
      this.map.closePopup();
    },
    /**
     * @params toAdd - checks if its adding or delete mode
     * Changes mode to Edit mode
     */
    editCurrentShapeModeOn(toAdd) {
      this.map.addControl(this.addDeletePartShapeModeDrawControl);
      this.map.removeControl(this.drawControl);
      this.map.closePopup();
      // new window.L.Draw.Rectangle(this.map).enable()
      if (toAdd) {
        this.addMoreShapeMode = true;
      } else {
        this.deletePartShapeMode = true;
      }
      // Make FSA layer button unclickable
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      fsaTargetElement.style.pointerEvents = "none";
    },
    /**
     * Reverts to normal mode if the app is currently on edit mode
     */
    closeAddDeletePartShapeMode() {
      // new window.L.Draw.Rectangle(this.map).disable()
      this.deletePartShapeMode = false;
      this.addMoreShapeMode = false;
      this.selectedShape = null;
      this.map.removeControl(this.addDeletePartShapeModeDrawControl);
      this.map.addControl(this.drawControl);
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      fsaTargetElement.style.pointerEvents = "";
    },
    /**
     * To render all of the zones to the map
     * Usually called after editing or writing on the Zone database
     */
    async renderZonesToMap(isGettingZone, region) {
      // this is to render all zones to the map
      const province = region;
      const friendlyName = this.regions.find((r) => r.id === province)
        ? this.regions.find((r) => r.id === province).name
        : "";
      if (isGettingZone) {
        this.$notify({
          group: "zones",
          title: "Getting Zones",
          closeOnClick: false,
          duration: -1,
          text: `Retrieving zones for ${friendlyName}, please wait...`,
        });
      } else {
        this.$notify({
          group: "zones",
          clean: true,
        });
        this.$notify({
          group: "zones",
          title: "Processing",
          closeOnClick: false,
          duration: -1,
          text: "Data processing, please wait...",
        });
      }
      try {
        this.showChangingRegionModal = true;
        const res = await getZones(province);
        const drawnShapes = res.map((doc) => ({ id: doc._id, ...doc }));
        this.$store.dispatch("setRegionZones", drawnShapes);
        this.zones = this.$store.state.zoneInformations;
        this.drawnZones.forEach((d) => {
          this.map.removeLayer(this.map._layers[d.leafletId]);
        });
        this.markerGroup.clearLayers();
        this.drawnZones = [];
        for (const x in this.drawnItems._layers) {
          this.drawnItems.removeLayer(this.drawnItems._layers[x]);
        }
        drawnShapes.map((shape) => {
          const coordinatesForLeaflet = shape.multi
            ? JSON.parse(shape.coordinates).map((shape) => {
                return shape.map((c) => [c[1], c[0]]);
              })
            : JSON.parse(shape.coordinates).map((c) => [c[1], c[0]]);
          const invertedOrderCoordinates = JSON.parse(shape.coordinates);
          const color = shape.color;
          const zone = shape.zone;
          const polygon = window.L.polygon(coordinatesForLeaflet, {
            color: color,
            fillOpacity: 0.3,
            weight: 0.5,
          }).addTo(this.map);
          const tooltip = window.L.tooltip({
            permanent: true,
            direction: "center",
            opacity: "1",
            className: "zone-tooltip",
          });
          tooltip.setContent(
            `<div style='background-color: transparent'> ${zone} </div>`
          );
          const { lat, lng } = polygon.getCenter();
          tooltip.setLatLng(new window.L.LatLng(lat, lng));
          this.markerGroup.addLayer(tooltip);
          const instance = EditShape(
            this.$store,
            invertedOrderCoordinates,
            shape,
            this.$store.state.selectedVehicles,
            this.map,
            true /* editable */,
            this.editCurrentShapeModeOn,
            this.renderZonesToMap,
            this.activateEditSelectableFSAMode,
            this.closeEditCurrentShapeMode
          );
          instance.$mount();
          const contentPopup = instance.$el;
          const zoneProperties = {
            zone,
            leafletId: polygon._leaflet_id,
            coordinates: coordinatesForLeaflet,
            id: shape.id,
            shapeId: shape.shapeId,
            zoneId: shape.zoneId,
            region: shape.region,
            multi: shape.multi,
            invertedCoordinates: invertedOrderCoordinates,
            daysAssigned: shape.daysAssigned,
            fsaIDs: shape.fsa,
          };
          console.log("zoneProperties: ", zoneProperties);
          this.drawnZones.push(zoneProperties);
          const popupContent = window.L.popup({
            autoPan: false,
            closeButton: false,
            autoClose: false,
          }).setContent(contentPopup);
          polygon.bindPopup(popupContent);
          this.drawnItems.addLayer(polygon);
        });

        this.$notify({
          group: "zones",
          clean: true,
        });
        this.$notify({
          type: "success",
          group: "zones",
          title: isGettingZone ? "Zones were retrieved" : "Zones were fetched",
          text: "Map is updated",
          duration: 3000,
        });
        this.showChangingRegionModal = false;
      } catch (err) {
        if (err.name !== "TypeError") {
          this.$notify({
            group: "zones",
            clean: true,
          });
          this.$notify({
            type: "error",
            group: "zones",
            title: "There was an error, please refresh the page again",
          });
        }
      }
    },
    /**
     * To render all of the FSA to the map
     * Automatically the app will be on add FSA mode
     */

    async renderFSAToMap(province) {
      this.drawnFSA = [];
      const FSALayerGroup = window.L.featureGroup();
      const FSANameLayerGroup = window.L.featureGroup();
      this.FSALayerGroup = FSALayerGroup;
      this.FSANameLayerGroup = FSANameLayerGroup;
      this.retrievingFSA = true;
      this.$notify({
        group: "fsa",
        title: "Getting FSA",
        closeOnClick: false,
        duration: -1,
        text: "Retrieving FSA, please wait...",
      });
      const res = await getFSA(province);
      try {
        const fsaLocations = res;
        this.markerGroupFSA.clearLayers();
        fsaLocations.map((shape) => {
          const coordinatesForLeaflet = shape.isMulti
            ? JSON.parse(shape.coordinates).map((shape) => {
                return shape.map((c) => [c[1], c[0]]);
              })
            : JSON.parse(shape.coordinates).map((c) => [c[1], c[0]]);

          const invertedCoordinates = JSON.parse(shape.coordinates);
          const FSA = shape.FSA;
          const FSA_ID = shape.FSA_ID;

          const polygon = window.L.polygon(coordinatesForLeaflet, {
            color: "black",
            weight: 1,
            fill: true,
            fillColor: "#A2D26C",
            fillOpacity: 0.4,
          }).addTo(FSALayerGroup);

          const FSAProperties = {
            FSA,
            FSA_ID,
            coordinates: coordinatesForLeaflet,
            invertedCoordinates,
            id: polygon._leaflet_id,
          };
          this.drawnFSA.push(FSAProperties);
          // make fsa clickbale
          polygon.on("click", async (e) => {
            const selectedFSAId = e.sourceTarget._leaflet_id;
            const selectedFSAShape = this.drawnFSA.find(
              (d) => d.id === selectedFSAId
            );
            console.log("selectedFSAShape.FSA_ID: ", selectedFSAShape.FSA_ID);
            console.log("selectedFSAShape: ", selectedFSAShape);

            if (this.selectableFSAMode) {
              const selectedFSAShapeToAdd = this.selectedFSAToAdd.find(
                (d) => d.id === selectedFSAId
              );
              if (!selectedFSAShapeToAdd) {
                workerSelectFSA.postMessage({
                  drawnZones: this.drawnZones,
                  editSelectableFSAMode: this.editSelectableFSAMode,
                  selectedShapeID: this.selectedShape && this.selectedShape.id,
                  selectedFSAShape,
                });
              } else {
                console.log("already added");
                this.selectedFSAToAdd = this.selectedFSAToAdd.filter(
                  (d) => d.id !== selectedFSAId
                );
                await this.renderSelectedFSAShape();
              }
            }
          });
          const polygonFSA = polygonTurf(
            shape.isMulti ? coordinatesForLeaflet : [coordinatesForLeaflet]
          );
          const {
            geometry: { coordinates: centerPolygon },
          } = centerMass(polygonFSA);
          const tooltip = window.L.tooltip({
            permanent: true,
            sticky: true,
            direction: "center",
            opacity: "1",
            className: "FSA_tooltip",
            pane: "tooltipPane",
          });
          tooltip.setContent(`${FSA}`);
          tooltip.setLatLng(centerPolygon);
          this.markerGroupFSA.addLayer(tooltip);
        });
        const smoothLayer = window.L.tileLayer(this.urlSmooth);
        const outdoorsLayer = window.L.tileLayer(this.urlOutdoors);
        const urlOsrmBright = window.L.tileLayer(this.urlOsrmBright);
        const smoothDarkLayer = window.L.tileLayer(this.urlSmoothDark);

        // Base Layers (only one can be active)
        const baseLayers = {
          "Grey Map": smoothLayer,
          "Colored Map": outdoorsLayer,
          "Dark Map": smoothDarkLayer,
          "Bright Map": urlOsrmBright,
        };

        urlOsrmBright.addTo(this.map);

        // Overlay Layers (can be toggled independently)
        const overLayerMaps = {
          "FSA Layer": FSALayerGroup,
          "FSA Name": this.markerGroupFSA,
          Stores: this.storeMarkers,
          Suppliers: this.supplierMarkers,
          Primary: this.primaryMarkers,
          Secondary: this.secondaryMarkers,
          Stem: this.stemMarkers,
        };
        // Create Layer Control with Base and Overlay Layers
        const layerControl = window.L.control.layers(
          baseLayers,
          overLayerMaps,
          {
            position: "topright",
          }
        );
        this.FSALayerControl = layerControl;
        window.FSALayerControl = layerControl;

        layerControl.addTo(this.map);
        this.storeMarkers.addTo(this.map);
        this.supplierMarkers.addTo(this.map);
        this.primaryMarkers.addTo(this.map);
        this.secondaryMarkers.addTo(this.map);
        this.stemMarkers.addTo(this.map);

        const fsaTargetElement = document.querySelector(
          ".leaflet-control-layers-overlays > :first-child"
        );
        const checkboxElement = fsaTargetElement.querySelector(
          ".leaflet-control-layers-selector"
        );
        const appThis = this;
        checkboxElement.addEventListener("change", async function () {
          if (this.checked) {
            await appThis.selectableFSAModeOn(true);
          } else {
            await appThis.closeSelectableFSAMode(false, true);
          }
        });
        this.$notify({
          group: "fsa",
          clean: true,
        });
        this.$notify({
          type: "success",
          group: "fsa",
          title: "FSA retrieved",
          text: `Fetched all FSA from ${province}`,
        });
        this.retrievingFSA = false;
        this.showChangingRegionModal = false;
        window.FSALayerGroup = this.FSALayerGroup;
      } catch (err) {
        this.$notify({
          group: "fsa",
          clean: true,
        });
        this.$notify({
          type: "error",
          group: "fsa",
          title: "FSA not retrieved",
          text: `${err}`,
        });
        this.retrievingFSA = false;
        this.showChangingRegionModal = false;
      }
    },
    /**
     * Open Modal on FSA mode when adding new shape
     */
    openAddFSAShapeZoneModal() {
      // after selecting some fsa
      // this will checker of the formats of the shape before
      // show the modal to comfirm it
      workerCombineSelectedFSA.postMessage({
        selectedFSAToAdd: this.selectedFSAToAdd,
        drawnFSA: this.drawnFSA,
        mode: "ADD",
      });
    },
    /**
     * Open Modal on FSA mode when editing shape
     */
    openEditFSAShapeZoneModal() {
      // after selecting some fsa
      // this will checker of the formats of the shape before
      // show the modal to comfirm it
      workerCombineSelectedFSA.postMessage({
        selectedFSAToAdd: this.selectedFSAToAdd,
        drawnFSA: this.drawnFSA,
        mode: "EDIT",
      });
    },
    /**
     * Show FSA of the current region
     */
    selectableFSAModeOn(clickByCheckBox = false) {
      // this will activate selectable fsa mdoe and show fsa layer
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      const checkboxElement = fsaTargetElement.querySelector(
        ".leaflet-control-layers-selector"
      );
      this.map.removeControl(this.drawControl);
      this.map.closePopup();
      this.selectableFSAMode = true;
      // this is for activating fsa layer manually
      if (!clickByCheckBox) {
        checkboxElement.click();
        fsaTargetElement.style.pointerEvents = "none";
      }
    },
    /**
     * Activate FSA by selecting a shape then render current FSA assign on the current selected shape
     */
    async activateEditSelectableFSAMode() {
      // this will trigger to show all fsa to be clickable
      // this also will show all the fsa that the current shape overlaps
      this.map.removeControl(this.drawControl);
      this.map.closePopup();
      if (!this.selectableFSAMode) {
        const fsaTargetElement = document.querySelector(
          ".leaflet-control-layers-overlays > :first-child"
        );
        const checkboxElement = fsaTargetElement.querySelector(
          ".leaflet-control-layers-selector"
        );
        checkboxElement.click();
        fsaTargetElement.style.pointerEvents = "none";
      }
      this.selectableFSAMode = true;
      this.editSelectableFSAMode = true;
      const selectedShape = this.selectedShape;
      await this.drawnFSA.forEach(async (fsa) => {
        try {
          // fsaIDs // FSA_ID
          if (selectedShape.fsaIDs.includes(fsa.FSA_ID)) {
            this.selectedFSAToAdd.push(fsa);
            await this.renderSelectedFSAShape();
          }
        } catch (err) {
          console.error("err: ", err);
        }
      });
      console.log("this.selectedFSAToAdd: ", this.selectedFSAToAdd);
    },
    /**
     * Hide FSA layers
     */
    closeSelectableFSAMode(changeRegion = false, checkByCheckbox = false) {
      if (!changeRegion) {
        const fsaTargetElement = document.querySelector(
          ".leaflet-control-layers-overlays > :first-child"
        );
        const checkboxElement = fsaTargetElement.querySelector(
          ".leaflet-control-layers-selector"
        );
        fsaTargetElement.style.pointerEvents = "";
        if (!checkByCheckbox) {
          checkboxElement.click();
        }
      }
      if (
        this.toBeSaveShapeFSAID &&
        this.map._layers[this.toBeSaveShapeFSAID]
      ) {
        this.map.removeLayer(this.map._layers[this.toBeSaveShapeFSAID]);
        this.toBeSaveShapeFSAID = null;
      }
      if (this.currentSelectedFSAShape.length > 0) {
        this.currentSelectedFSAShape.forEach((fsa) => {
          if (this.map._layers[fsa.id]) {
            this.map.removeLayer(this.map._layers[fsa.id]);
          }
        });
      }
      this.selectedShape = null;
      this.selectedFSAToAdd = [];
      this.currentSelectedFSAShape = [];
      this.map.addControl(this.drawControl);
      this.selectableFSAMode = false;
      this.editSelectableFSAMode = false;
      this.FSAConfirmMode = false;
      this.onEditProcess = false;
    },
    /**
     * To render every FSA on click
     */
    renderSelectedFSAShape() {
      // console.log('RENDER THIS', this.selectedFSAToAdd)
      const wholeSelectedZone = this.selectedFSAToAdd.map((fsa) => ({
        coordinates: fsa.coordinates,
        fsaId: fsa.id,
      }));
      if (wholeSelectedZone.length > 0) {
        this.currentSelectedFSAShape.forEach((fsaProperty) => {
          this.map.removeLayer(this.map._layers[fsaProperty.id]);
        });
        this.currentSelectedFSAShape = [];
        wholeSelectedZone.map((fsa) => {
          const polygon = window.L.polygon([fsa.coordinates], {
            color: "black",
            weight: 1,
            fill: true,
            fillColor: "black",
            fillOpacity: 0.8,
            pane: "shadowPane",
          }).addTo(this.map);
          const selectedShapeProperty = {
            id: polygon._leaflet_id,
          };
          this.currentSelectedFSAShape.push(selectedShapeProperty);
          polygon.on("click", async (e) => {
            const selectedCurrentFSAId = e.sourceTarget._leaflet_id;
            this.map.removeLayer(this.map._layers[selectedCurrentFSAId]);
            this.currentSelectedFSAShape = this.currentSelectedFSAShape.filter(
              (d) => d.id !== selectedCurrentFSAId
            );
            this.selectedFSAToAdd = this.selectedFSAToAdd.filter(
              (d) => d.id !== fsa.fsaId
            );
          });
        });
      }
    },
    /**
     * Confirming shape after you the select it and show modal to choose what zone to assign
     */
    async renderFSAZoneToAddModal(
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      // this is the confirmation after adding some selected fsa and
      // display the modal
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      // const checkboxElement = fsaTargetElement.querySelector(
      //   '.leaflet-control-layers-selector'
      // )
      fsaTargetElement.style.pointerEvents = "none";
      this.FSAConfirmMode = true;
      this.selectableFSAMode = false;
      this.currentSelectedFSAShape.forEach((fsaProperty) => {
        this.map.removeLayer(this.map._layers[fsaProperty.id]);
      });
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: "black",
        weight: 1,
        fill: true,
        fillColor: "black",
        fillOpacity: 0.8,
        // pane: 'tilePane'
      });
      // .addTo(this.map)
      // console.log('invertedCenterCoordinate: ', invertedCenterCoordinate)
      this.drawnItems.addLayer(polygon);
      const ComponentClass = Vue.extend(AddFSAZoneModal);
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          region: this.$store.state.region,
          shapeType,
        },
        methods: {
          close: () => {
            this.map.closePopup();
          },
          updateMap: async (data) => {
            const { shapesToAdd, zoneId, shapeType } = data;
            if (shapeType === "SINGLE_SHAPE") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates[0],
                shapesID: zoneId,
                multi: false,
                fsa,
              });
            } else if (shapeType === "SINGLE_SHAPE_WITH_HOLES") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates,
                shapesID: zoneId,
                multi: true,
                fsa,
              });
            } else if (shapeType === "MULTI_SINGLE_SHAPE") {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  this.pushToQueue("INSERT_NEW_SHAPE", {
                    coordinates: coordinates,
                    shapesID: zoneId,
                    multi: false,
                    fsa,
                  });
                })
              );
            } else {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  if (coordinates.length > 1) {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates,
                      shapesID: zoneId,
                      multi: true,
                      fsa,
                    });
                  } else {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates[0],
                      shapesID: zoneId,
                      multi: false,
                      fsa,
                    });
                  }
                })
              );
            }
          },
          removeShape: async () => {
            this.toBeSaveShapeFSAID = null;
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
            await this.closeSelectableFSAMode();
          },
          cancelEdit: async () => {
            await this.closeSelectableFSAMode();
          },
        },
      });
      instance.$mount();
      const contentPopup = instance.$el;
      const popupContent = window.L.popup({
        autoPan: false,
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
      }).setContent(contentPopup);
      polygon.bindPopup(popupContent).openPopup();
      this.toBeSaveShapeFSAID = polygon._leaflet_id;
    },
    /**
     * Confirming shape after adding to show modal what zone to assign
     */
    async renderNewZoneToAddModal(
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: "black",
        weight: 1,
        fill: true,
        fillColor: "black",
        fillOpacity: 0.8,
      });
      this.drawnItems.addLayer(polygon);
      const ComponentClass = Vue.extend(AddFSAZoneModal);
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          region: this.$store.state.region,
          shapeType,
        },
        methods: {
          close: () => {
            this.$notify({
              group: "zones",
              clean: true,
            });
            this.map.closePopup();
          },
          updateMap: async (data) => {
            const { shapesToAdd, zoneId, shapeType } = data;
            if (shapeType === "SINGLE_SHAPE") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates[0],
                shapesID: zoneId,
                multi: false,
                fsa,
              });
            } else if (shapeType === "SINGLE_SHAPE_WITH_HOLES") {
              const coordinates = shapesToAdd.coordinates;
              const fsa = shapesToAdd.fsa;
              this.pushToQueue("INSERT_NEW_SHAPE", {
                coordinates: coordinates,
                shapesID: zoneId,
                multi: true,
                fsa,
              });
            } else if (shapeType === "MULTI_SINGLE_SHAPE") {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  this.pushToQueue("INSERT_NEW_SHAPE", {
                    coordinates: coordinates,
                    shapesID: zoneId,
                    multi: false,
                    fsa,
                  });
                })
              );
            } else {
              await Promise.all(
                shapesToAdd.map(async (shape) => {
                  const coordinates = shape.coordinates;
                  const fsa = shape.fsa;
                  if (coordinates.length > 1) {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates,
                      shapesID: zoneId,
                      multi: true,
                      fsa,
                    });
                  } else {
                    this.pushToQueue("INSERT_NEW_SHAPE", {
                      coordinates: coordinates[0],
                      shapesID: zoneId,
                      multi: false,
                      fsa,
                    });
                  }
                })
              );
            }
          },
          removeShape: async () => {
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
          },
          cancelEdit: async () => {
            this.map.closePopup();
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
          },
        },
      });
      instance.$mount();
      const contentPopup = instance.$el;
      const popupContent = window.L.popup({
        autoPan: false,
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
      }).setContent(contentPopup);
      polygon.bindPopup(popupContent).openPopup();
    },
    /**
     * Confirming shape after you the select it and show modal to choose what zone to assign
     */
    async renderFSAZoneToEditModal(
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      // this is the confirmation after adding some selected fsa and
      // display the modal
      const fsaTargetElement = document.querySelector(
        ".leaflet-control-layers-overlays > :first-child"
      );
      // const checkboxElement = fsaTargetElement.querySelector(
      //   '.leaflet-control-layers-selector'
      // )
      fsaTargetElement.style.pointerEvents = "none";
      this.FSAConfirmMode = true;
      this.selectableFSAMode = false;
      const selectedShape = this.selectedShape;
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: "black",
        weight: 1,
        fill: true,
        fillColor: "black",
        fillOpacity: 0.8,
        // pane: 'tilePane'
      });
      this.drawnItems.addLayer(polygon);
      const ComponentClass = Vue.extend(EditFSAZoneModal);
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          shapeType,
          shape: selectedShape,
          editable: true,
        },
        methods: {
          close: () => {
            this.map.closePopup();
          },
          updateZone: async (state) => {
            const { coordinates, shapeId, multi, fsa } = state;
            this.onEditProcess = true;
            await this.pushToQueue("EDIT_COORDINATES", {
              coordinates,
              shapeId,
              multi,
              fsa,
            });
          },
          updateMultipleZone: async (state) => {
            const { newShapes, currentShape, shapeType } = state;
            this.onEditProcess = true;
            this.pushToQueue("EDIT_MULTIPLE_ZONE_FSA", {
              newShapes,
              currentShape,
              shapeType,
            });
          },
          activateEditOnProcess: () => {
            this.onEditProcess = true;
          },
          updateMap: async () => {
            this.$notify({
              group: "zones",
              clean: true,
            });
          },
          removeShape: async () => {
            this.toBeSaveShapeFSAID = null;
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id]);
            }
            await this.closeSelectableFSAMode();
          },
          cancelEdit: async () => {
            await this.closeSelectableFSAMode();
          },
        },
      });
      // .addTo(this.map)
      instance.$mount();
      const contentPopup = instance.$el;
      const popupContent = window.L.popup({
        autoPan: false,
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
      }).setContent(contentPopup);
      polygon.bindPopup(popupContent).openPopup();
      this.toBeSaveShapeFSAID = polygon._leaflet_id;
    },
    async refresh() {
      this.refreshLoading = true;
      await this.renderZonesToMap(false, this.$store.state.region);
      this.refreshLoading = false;
    },
    errorNotificatation() {
      this.$notify({
        group: "zones",
        clean: true,
      });
      this.$notify({
        type: "error",
        group: "zones",
        title: "Shape is not saved",
        text: "The shape is not a valid shape",
      });
      this.closeAddDeletePartShapeMode();
    },
    /* Push any action to the queue to be processed */
    async pushToQueue(action, state) {
      return this.queue.push({
        action,
        state,
      });
    },
    removeActionInQueue(id) {
      this.queue.splice(id, 1);
    },
    logout() {
      this.$store.dispatch("resetValues");
      localStorage.removeItem("user");
      localStorage.removeItem("access");
      localStorage.removeItem("environment");
      auth.signOut();
      this.$router.push("/");
    },
  },
  computed: {
    ...mapState(["region"]),
  },
  async mounted() {
    // this.$tours.myTour.start();

    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.$router.push("/");
      } else if (user) {
        await this.$store.dispatch("updateUser", user);
      }
    });

    await this.$store.dispatch("onLoad");
    await this.$store.dispatch("initialize");
    const currentEnvironment = this.$router.currentRoute.name;
    this.$store.dispatch("setEnvironment", currentEnvironment);
    this.showChangingRegionModal = true;
    this.mappingLoaded = true;
    this.map = this.$refs.map.mapObject;
    this.spiderfy = new OverlappingMarkerSpiderfier(this.map, {
      nearbyDistance: 5,
    });
    // add listener to prevent popup from showing when click the one of the overlapping markers
    this.spiderfy.addListener("spiderfy", () => {
      this.map.closePopup();
    });
    window.map = this.map;
    const drawnItems = new window.L.FeatureGroup();
    this.drawnItems = drawnItems;
    const FSAItems = new window.L.FeatureGroup();
    this.FSAItems = FSAItems;
    const drawnMarkers = new window.L.FeatureGroup();
    const drawnMarkersPostalCodes = new window.L.FeatureGroup();
    const markerGroup = window.L.markerClusterGroup({
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
      chunkedLoading: true,
      animate: false,
      maxClusterRadius: 100,
      iconCreateFunction: () => {
        return window.L.divIcon({ html: "", className: "marker-cluster-icon" });
      },
    });
    const markerGroupFSA = window.L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      chunkedLoading: true,
      animate: true,
      maxClusterRadius: 50,
    });
    this.markerGroup = markerGroup;
    this.markerGroupFSA = markerGroupFSA;
    this.postalCodesGroup = drawnMarkersPostalCodes;
    // Define you draw handler somewhere where you click handler can access it. N.B. pass any draw options into the handler
    this.map.addLayer(this.drawnItems);
    this.map.addLayer(drawnMarkers);
    this.map.addLayer(drawnMarkersPostalCodes);
    this.map.addLayer(markerGroup);
    // this.map.addLayer(markerGroupFSA)
    // toolbar for polygon
    window.L.EditToolbar.Delete.include({
      removeAllLayers: false,
    });
    const drawControl = new window.L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        edit: false,
      },
      draw: {
        polyline: true,
        polygon: {
          shapeOptions: {
            clickable: true,
            color: "#00D1B2",
          },
          allowIntersection: false,
        },
        rectangle: {
          shapeOptions: {
            clickable: true,
            color: "#00D1B2",
          },
        },
        marker: {
          shapeOptions: {
            clickable: true,
            color: "#6169e0",
          },
        },
        circlemarker: false,
      },
      position: "topright",
    });

    const provider = new GoogleProvider({ apiKey: 'AIzaSyD8O0Tq7WeY3zAQUl3crewR54CVBkVEq9A' });

    const searchControl =
      new SearchControl({
        style: 'button',
        provider,
        notFoundMessage: 'No results were found',
      });

    this.map.addControl(searchControl);

    const results = window.L.layerGroup().addTo(this.map);

    // this control is for add or deleting shapes in a zone
    const addDeletePartShapeModeDrawControl = new window.L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        edit: false,
        remove: false,
      },
      draw: {
        polyline: false,
        polygon: {
          shapeOptions: {
            clickable: true,
            color: "#007D6A",
          },
          allowIntersection: false,
        },
        rectangle: {
          shapeOptions: {
            clickable: true,
            color: "#ED184F",
          },
        },
        circle: false,
        marker: false,
        circlemarker: false,
      },
      position: "topright",
    });
    this.drawControl = drawControl;
    this.addDeletePartShapeModeDrawControl = addDeletePartShapeModeDrawControl;
    this.map.addControl(this.drawControl);

    try {
      workerExportPostalCode.addEventListener("message", async (event) => {
        if (event.data.type === "export") {
          const { data: blob } = event.data;
          const fileStream = streamSaver.createWriteStream(
            `zones-postalcode.xlsx`,
            {
              size: blob.size, // Makes the percentage visible in the download
            }
          );
          const readableStream = blob.stream();
          // more optimized pipe version
          // (Safari may have pipeTo but it's useless without the WritableStream)
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream
              .pipeTo(fileStream)
              .then(() => console.log("done writing"));
          }
          // Write (pipe) manually
          const writer = fileStream.getWriter();
          const reader = readableStream.getReader();
          const pump = () =>
            reader
              .read()
              // @ts-ignore
              .then((res) =>
                res.done ? writer.close() : writer.write(res.value).then(pump)
              );
          pump();
          this.showChangingRegionModal = false;
          this.importingLoading = false;
          this.$notify({
            type: "success",
            group: "zones",
            title: "Export File Success",
          });
        }
      });
      workerAddMoreShape.addEventListener("message", async (event) => {
        if (event.data.type === "addMoreShapeOverlapping") {
          const { removeOverlapCoordinates, selectedShape, shapesOverlaped } =
            event.data;
          const mongoAppForShape = await mongoDbAppForShape();
          const zonesCollection = mongoAppForShape.collection("zones");
          const currentSelectedShape = await zonesCollection.findOne({
            _id: ObjectId(selectedShape.id),
          });
          const currentSelectedShapeCoordinates = currentSelectedShape.multi
            ? JSON.parse(currentSelectedShape.coordinates).map((shape) => {
                return shape.map((c) => [c[1], c[0]]);
              })
            : JSON.parse(currentSelectedShape.coordinates).map((c) => [
                c[1],
                c[0],
              ]);
          const currentSelectedShapeInvertedOrderCoordinates = JSON.parse(
            currentSelectedShape.coordinates
          );
          const selectedShapeUpdated = {
            ...currentSelectedShape,
            coordinates: currentSelectedShapeCoordinates,
            invertedCoordinates: currentSelectedShapeInvertedOrderCoordinates,
            shapeId: currentSelectedShape._id.toString(),
            id: currentSelectedShape._id.toString(),
          };
          workerAddMoreShapeOverlapping.postMessage({
            selectedShape: selectedShapeUpdated,
            overlapCoordinates: removeOverlapCoordinates,
            drawnFSA: this.drawnFSA,
            shapesOverlaped,
          });
        } else if (event.data.type === "callEditZoneCoordinates") {
          const { combineCoordinates, shapeId, multi, fsa } = event.data;
          console.log("callEditZoneCoordinates_ADD:");
          await this.pushToQueue("EDIT_COORDINATES", {
            coordinates: combineCoordinates,
            shapeId,
            multi,
            fsa,
          });
        } else if (event.data.type === "errorNotificatation") {
          this.onEditProcess = false;
          this.$notify({ group: "zones", clean: true });
          this.$notify({
            type: "error",
            group: "zones",
            title: "Please Try Again",
          });
        }
        this.closeAddDeletePartShapeMode();
      });
      workerDeletePartShapeMode.addEventListener("message", async (event) => {
        if (event.data.type === "callEditZoneCoordinates") {
          const { combineCoordinates, shapeId, multi, fsa } = event.data;
          await this.pushToQueue("EDIT_COORDINATES", {
            coordinates: combineCoordinates,
            shapeId,
            multi,
            fsa,
          });
        } else if (event.data.type === "errorNotificatation") {
          this.$notify({ group: "zones", clean: true });
          this.$notify({
            type: "error",
            group: "zones",
            title: "Please Try Again",
          });
          this.onEditProcess = false;
          this.closeAddDeletePartShapeMode();
        } else if (event.data.type === "cantDeleteShapeNotification") {
          this.$notify({ group: "zones", clean: true });
          this.$notify({
            type: "error",
            group: "zones",
            title: "Can't delete shape",
            text: "You cannot delete the entire shape",
          });
          this.onEditProcess = false;
        }
        this.closeAddDeletePartShapeMode();
      });
      workerAddMoreShapeOverlapping.addEventListener(
        "message",
        async (event) => {
          console.log("workerAddMoreShapeOverlapping:", event.data);
          if (event.data.type === "callEditZoneCoordinates") {
            const { overlapCoordinates, shapeId, multi, fsa, shapesOverlaped } =
              event.data;
            console.log("callEditZoneCoordinates_ADD_OVER:");
            await this.pushToQueue("EDIT_COORDINATES", {
              coordinates: overlapCoordinates,
              shapeId,
              multi,
              fsa,
            });
            for (const shapesId of shapesOverlaped) {
              const mongoAppForShape = await mongoDbAppForShape();
              const zonesCollection = mongoAppForShape.collection("zones");
              const currentSelectedShape = await zonesCollection.findOne({
                _id: ObjectId(shapesId),
              });
              console.log("currentSelectedShape:", currentSelectedShape);
              const currentSelectedShapeInvertedOrderCoordinates = JSON.parse(
                currentSelectedShape.coordinates
              );
              console.log("overlappedShaped:", currentSelectedShape);
              const { shapesID, multi } = currentSelectedShape;
              await updatePostalCode(
                currentSelectedShapeInvertedOrderCoordinates,
                shapesID,
                multi
              );
            }
          } else if (event.data.type === "insertZone") {
            const { shapesID, coordinates, multi, fsa } = event.data;
            this.pushToQueue("INSERT_NEW_SHAPE", {
              shapesID,
              coordinates,
              multi,
              fsa,
            });
          } else if (event.data.type === "deleteZone") {
            console.log("delete process", event.data)
            const { selectedShapeId } = event.data;
            this.pushToQueue("DELETE_SHAPE", {
              shapeId: selectedShapeId,
            });
          } else if (event.data.type === "errorNotificatation") {
            this.$notify({ group: "zones", clean: true });
            this.$notify({
              type: "error",
              group: "zones",
              title: "Please Try Again",
            });
            this.closeAddDeletePartShapeMode();
            this.onEditProcess = false;
          } else if (event.data.type === "done") {
            this.onEditProcess = false;
          } else if (event.data.type === "updateOverlapShapes") {
            const { shapesOverlaped } = event.data;
            for (const shapesId of shapesOverlaped) {
              const mongoAppForShape = await mongoDbAppForShape();
              const zonesCollection = mongoAppForShape.collection("zones");
              const currentSelectedShape = await zonesCollection.findOne({
                _id: ObjectId(shapesId),
              });
              console.log("currentSelectedShape:", currentSelectedShape);
              const currentSelectedShapeInvertedOrderCoordinates = JSON.parse(
                currentSelectedShape.coordinates
              );
              console.log("overlappedShaped:", currentSelectedShape);
              const { shapesID, multi } = currentSelectedShape;
              console.log("check Update PostalCode update///");
              await updatePostalCode(
                currentSelectedShapeInvertedOrderCoordinates,
                shapesID,
                multi
              );
            }
          }
        }
      );
      workerAddShape.addEventListener("message", async (event) => {
        if (event.data.type === "renderNewZoneAddToModal") {
          const { coordinates, shapesToAdd, shapeType } = event.data;
          this.renderNewZoneToAddModal(coordinates, shapesToAdd, shapeType);
        } else if (
          event.data.type === "renderNewZoneAddToModalNotIntersecting"
        ) {
          const { coordinates, coordinateForLeaflet } = event.data;
          const layer = window.L.polygon(coordinateForLeaflet, {
            color: "black",
            fillOpacity: 0.6,
            weight: 0.5,
          });
          // layer = newCircle;
          this.drawnItems.addLayer(layer);
          const ComponentClass = Vue.extend(AddShapeModal);
          const instance = new ComponentClass({
            propsData: {
              coordinates: coordinates,
              region: this.$store.state.region,
            },
            methods: {
              close: () => {
                this.map.closePopup();
                if (this.map._layers[layer._leaflet_id]) {
                  this.map.removeLayer(this.map._layers[layer._leaflet_id]);
                }
              },
              updateMap: async (props) => {
                const { coordinates, zoneId } = props;
                this.pushToQueue("INSERT_NEW_SHAPE", {
                  shapesID: zoneId,
                  coordinates,
                  multi: false,
                  fsa: [],
                });
              },
            },
          });
          instance.$mount();
          const contentPopup = instance.$el;
          const popupCoords = layer.getCenter();
          window.L.popup({
            autoPan: false,
            keepInView: true,
            closeButton: true,
            autoClose: true,
            closeOnClick: false,
          })
            .setLatLng([popupCoords.lat, popupCoords.lng])
            .setContent(contentPopup)
            .openOn(this.map);
          layer.bindPopup(contentPopup);
          this.$notify({
            group: "zones",
            clean: true,
          });
        }
        this.$notify({ group: "zones", clean: true });
        this.$notify({
          type: "success",
          group: "zones",
          title: "Processing...",
          text: "Map Drawn",
          duration: 5000,
        });
      });
      workerSelectFSA.addEventListener("message", async (event) => {
        //
        if (event.data.type === "addFSAToShape") {
          const { shape } = event.data;
          console.log("shape:", shape);
          this.selectedFSAToAdd.push(shape);
          this.renderSelectedFSAShape();
        } else if (event.data.type === "error") {
          this.onEditProcess = false;
          this.$notify({
            type: "error",
            group: "zones",
            title: "There is an overlap",
            text: "The FSA you selected overlaps a existing zone",
          });
        }
      });
      workerCombineSelectedFSA.addEventListener("message", async (event) => {
        if (event.data.type === "renderFSAZoneToModal") {
          const { coordinates, shapesToAdd, shapeType, mode } = event.data;
          if (mode === "ADD") {
            this.renderFSAZoneToAddModal(coordinates, shapesToAdd, shapeType);
          } else {
            this.renderFSAZoneToEditModal(coordinates, shapesToAdd, shapeType);
          }
        } else {
          this.$notify({
            type: "warn",
            group: "zones",
            title: "There is an error",
            text: "Please try again",
          });
        }
      });
    } catch (error) {
      console.log("error:", error);
    }

    // on select shape
    this.drawnItems.on("click", (e) => {
      const selectedShapeId = e.sourceTarget._leaflet_id;
      const selectedShape = this.drawnZones.find(
        (d) => d.leafletId === selectedShapeId
      );
      console.log("selectedShape: ", selectedShape, selectedShapeId);
      if (
        selectedShape &&
        !this.editShapeSelectedMode &&
        !this.addMoreShapeMode &&
        !this.deletePartShapeMode &&
        !this.selectableFSAMode &&
        !this.editSelectableFSAMode
      ) {
        this.selectedShape = selectedShape;
      }
    });
    this.map.on("draw:created", async (e) => {
      const layer = e.layer;
      // check if the mode is in delete or adding more shapes to a zone or
      // adding parts zone
      // delete parts zone
      // add new zone or marker
      if (this.addMoreShapeMode) {
        this.$notify({
          group: "zones",
          title: "Processing...",
          text: "Updating Zone, please wait",
          closeOnClick: false,
          duration: 10000,
        });
        try {
          const selectedShape = this.selectedShape;
          const coordinates = layer.getLatLngs();
          if (this.onEditProcess) {
            this.$notify({
              group: "zones",
              clean: true,
            });
            this.$notify({
              type: "warn",
              group: "zones",
              title: "Currently process edit shape",
              text: "Please wait for it to be finish",
            });
            this.closeAddDeletePartShapeMode();
            return;
          }
          if (!selectedShape) {
            this.$notify({
              type: "warn",
              group: "zones",
              title: "There is an error",
              text: "Please try again",
            });
            this.closeAddDeletePartShapeMode();
            return;
          }
          this.onEditProcess = true;
          this.pushToQueue("PROCESS_EDITING_ADD", {
            selectedShape,
            coordinates,
            drawnZones: this.drawnZones,
          });
          this.closeAddDeletePartShapeMode();
        } catch (err) {
          console.error("err:", err);
          this.$notify({
            type: "warn",
            group: "zones",
            title: "Error.",
            text: "There is something wrong on updating zone, please try again.",
          });
          this.closeAddDeletePartShapeMode();
        }
      } else if (this.deletePartShapeMode) {
        this.$notify({
          group: "zones",
          title: "Processing...",
          text: "Updating Zone, please wait",
          closeOnClick: false,
          duration: -1,
        });
        try {
          const coordinates = layer.getLatLngs();
          const selectedShape = this.selectedShape;
          if (this.onEditProcess) {
            this.$notify({
              group: "zones",
              clean: true,
            });
            this.$notify({
              type: "warn",
              group: "zones",
              title: "Currently process edit shape",
              text: "Please wait for it to be finish",
            });
            this.closeAddDeletePartShapeMode();
            return;
          }
          if (!selectedShape) {
            this.$notify({
              type: "warn",
              group: "zones",
              title: "There is an error",
              text: "Please try again",
            });
            this.closeAddDeletePartShapeMode();
            return;
          }
          setTimeout(async () => {
            this.onEditProcess = true;
            this.pushToQueue("PROCESS_EDITING_REMOVE", {
              selectedShape,
              coordinates,
              drawnZones: this.drawnZones,
            });
            this.closeAddDeletePartShapeMode();
          }, 500);
        } catch (err) {
          console.log("err:", err);
          this.$notify({
            type: "warn",
            group: "zones",
            title: "Error.",
            text: "There is something wrong on updating zone, please try again.",
          });
          this.closeAddDeletePartShapeMode();
        }
      } else {
        /* If it's a marker being added */
        if (e.layerType === "marker") {
          const { lat, lng } = layer._latlng;
          const marker = new window.L.Marker([lat, lng], {
            icon: this.getIcon("#007D6A"),
            riseOnHover: true,
            draggable: true,
          });

          const ComponentClass = Vue.extend(AddMarkerZoneModal);

          const gjLayer = window.L.geoJson(JSON.parse(geoJsonFeature));

          const firstPointResult = leafletPip.pointInLayer([lng, lat], gjLayer);
          const instance = new ComponentClass({
            propsData: {
              timeZone: firstPointResult[0].feature.properties.tz_name1st || "",
              coordinates: { lat, lng },
              region: this.$store.state.region,
            },
            methods: {
              close: () => {
                if (this.map._layers[marker._leaflet_id]) {
                  this.map.removeLayer(this.map._layers[marker._leaflet_id]);
                }
                this.map.closePopup();
              },
            },
          });
          instance.$mount();
          const contentPopup = instance.$el;

          window.L.popup({
            keepInView: true,
            closeButton: true,
            autoClose: true,
            autoPan: true,
            closeOnClick: false,
          })
            .setLatLng([lat, lng])
            .setContent(contentPopup)
            .on("remove", () => {
              if (this.map._layers[marker._leaflet_id]) {
                this.map.removeLayer(this.map._layers[marker._leaflet_id]);
              }
            })
            .openOn(this.map);

          marker.bindPopup(contentPopup);

          marker.on("dragstart", () => {
            this.map.closePopup();
          });

          marker.on("dragend", (e) => {
            const { lat, lng } = e.target._latlng;
            const draggedPointResult = leafletPip.pointInLayer([lng, lat], gjLayer);
            instance.timeZone = draggedPointResult[0].feature.properties.tz_name1st || "";
            instance.coordinates = { lat, lng };
            instance.$mount();
            const _contentPopup = instance.$el;
            marker.setPopupContent(_contentPopup);
            marker.openPopup();
          });

          drawnMarkers.addLayer(marker);
          // add marker to spiderfy instance
          this.spiderfy.addMarker(marker);
        } else {
          let coordinates = null;
          let coordinateForLeaflet = null;
          // this.drawnItems.addLayer(layer);
          if (e.layerType === "circle") {
            const latLng = layer.getLatLng();
            const radius = layer.getRadius(); // in m
            const center = [latLng.lng, latLng.lat];
            const radiusToKm = radius / Math.pow(10, 3);
            const circle = circleTurf(center, radiusToKm);
            coordinates = circle.geometry.coordinates[0];
            coordinateForLeaflet = coordinates.map((c) => [c[1], c[0]]);
            coordinateForLeaflet.push([
              coordinateForLeaflet[0][0],
              coordinateForLeaflet[0][1],
            ]);
          } else {
            coordinates = layer.getLatLngs()[0].map((c) => [c.lng, c.lat]);
            coordinates.push([coordinates[0][0], coordinates[0][1]]);
            coordinateForLeaflet = coordinates.map((c) => [c[1], c[0]]);
            coordinateForLeaflet.push([
              coordinateForLeaflet[0][0],
              coordinateForLeaflet[0][1],
            ]);
          }
          if (coordinates.length <= 0) {
            return;
          }
          const coods = coordinates;
          this.$notify({
            group: "zones",
            title: "Processing...",
            text: "Currently draw shape to the map, please wait",
            closeOnClick: false,
            duration: -1,
          });
          return workerAddShape.postMessage({
            coordinates: coods,
            coordinateForLeaflet,
            drawnZones: this.drawnZones,
            drawnFSA: this.drawnFSA,
          });
        }
      }
      this.closeAddDeletePartShapeMode();
    });
    // on edit save/stop
    this.map.on("draw:editstop", () => {
      // if the user save or cancel, this must be updated
      this.editedZones.map((zone) => {
        const drawnZone = this.drawnZones.find(
          (o) => o.leafletId === zone.leafletId
        );
        const coordinates = drawnZone.coordinates.map((c) => [c[1], c[0]]);
        const instance = EditShape(
          this.$store,
          coordinates,
          zone,
          this.vehicles,
          this.map,
          true /* editable */,
          this.editCurrentShapeModeOn,
          this.renderZonesToMap,
          this.activateEditSelectableFSAMode,
          this.closeEditCurrentShapeMode
        );
        instance.$mount();
        const contentPopup = instance.$el;
        this.map._layers[zone.leafletId].setPopupContent(contentPopup);
      });
    });
    // on edit move vertex
    this.map.on("draw:editvertex", (e) => {
      const poly = e.poly;
      const latlngs = poly.getLatLngs()[0].map((c) => [c.lng, c.lat]);
      latlngs.push([latlngs[0][0], latlngs[0][1]]);
      const leafletId = poly._leaflet_id;
      const drawnZone = this.drawnZones.find((o) => o.leafletId === leafletId);
      if (!this.editedZones.find((o) => o.leafletId === leafletId)) {
        this.editedZones.push({
          leafletId,
          zone: drawnZone.zone,
          coordinates: latlngs,
          id: drawnZone.id,
          multi: drawnZone.multi,
        });
      } else {
        const editedDrawnZoneIndex = this.editedZones.findIndex(
          (o) => o.leafletId === leafletId
        );
        this.editedZones[editedDrawnZoneIndex].coordinates = latlngs;
      }
      const instance = EditShape(
        this.$store,
        latlngs,
        drawnZone,
        this.vehicles,
        this.map,
        true /* editable */,
        this.editCurrentShapeModeOn,
        this.renderZonesToMap,
        this.activateEditSelectableFSAMode,
        this.closeEditCurrentShapeMode
      );
      instance.$mount();
      const contentPopup = instance.$el;
      poly.setPopupContent(contentPopup);
    });
    // on delete
    this.map.on("draw:deleted", async (e) => {
      console.log("delete process on", e, this.drawnZones)
      this.$notify({
        group: "zones",
        clean: true,
      });
      this.$notify({
        group: "zones",
        title: "Processing",
        closeOnClick: false,
        duration: -1,
        text: "Data processing, please wait...",
      });
      const deleteLayersId = Object.keys(e.layers._layers).map(Number);
      Promise.all(
        deleteLayersId.map(async (id) => {
          const drawnZone = this.drawnZones.find((o) => o.leafletId === id);
          // remove draw zone from the leaflet
          this.drawnZones = this.drawnZones.filter((o) => o.leafletId !== id);
          /* todo: whats this? */
          // const regions = this.regions;
          // when removing zone, you must remove the assigned zone from the truck
          // regions.map(async (reg, index) => {
          //   const selectedTruck = this.vehicleObj[this.regions[index]]
          //     .map((t, index) => ({ ...t, truckIndex: index }))
          //     .filter((truck) => truck.zone === drawnZone.zone);
          //   selectedTruck.map((truck) => {
          //     const payload = {
          //       value: "",
          //       key: "zone",
          //       index: truck.truckIndex,
          //       region: reg,
          //     };
          //     this.$store.dispatch("setTruckFleetSetting", payload);
          //   });
          //   await fleetSettings
          //     .doc(`${reg}`)
          //     .set({ fleet: this.$store.state.fleetSettings[reg] });
          // });
          this.pushToQueue("DELETE_SHAPE", {
            shapeId: drawnZone.shapeId,
          });
        })
      );
    });

    try {
      vehiclesCollection.onSnapshot(async (snapshot) => {
        let vehicles = {};
        snapshot.forEach((doc) => {
          const vehicle = doc.data();
          const region = vehicle.Region; // region doc id
          if (vehicles[region]) {
            vehicles[region].push({ ...vehicle });
          } else {
            vehicles = {
              ...vehicles,
              [region]: [{ ...vehicle }],
            };
          }
        });
        await this.$store.dispatch("setVehicles", vehicles);
      });
      sharedViewsCollection.orderBy("name").onSnapshot(async (snapshot) => {
        const sharedViews = await snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        await this.$store.dispatch("setSharedViews", sharedViews);
      });
      regionsCollection.orderBy("name").onSnapshot(async (snapshot) => {
        const regionsData = await snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        this.$store.dispatch("setRegions", regionsData);
        this.regions = regionsData;
        if (!this.initialLoadRegion) {
          if (this.regions.length > 0) {
            this.showRegionList = false;
            this.center = regionCenter(this.regions[0].name);
            this.$store.dispatch("setRegion", this.regions[0].id);
            this.renderZonesToMap(true, this.regions[0].id);
            this.initialLoadRegion = true;
          } else {
            this.showRegionList = true;
            this.initialLoadRegion = true;
          }
        }
        if (regionsData.length <= 0) {
          this.showRegionList = true;
          return;
        }
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            // if regions was empty at first and new one region added, then load the first region
            if (regionsData.length === 1) {
              this.$store.dispatch("setRegion", regionsData[0].id);
              this.renderZonesToMap(true, regionsData[0].id);
            }
          }
          if (change.type === "modified") {
            // this will call because adding region will set region
            if (regionsData.length === 1) {
              this.$store.dispatch("setRegion", regionsData[0].id);
              this.renderZonesToMap(true, regionsData[0].id);
            }
          }
          if (change.type === "removed") {
            console.log("data deleted:", change.doc.data());
            if (this.$store.state.region === change.doc.data().id) {
              if (regionsData.length > 0) {
                this.$store.dispatch("setRegion", regionsData[0].id);
                this.renderZonesToMap(true, regionsData[0].id);
              } else {
                this.$store.dispatch("setRegion", "");
                this.showRegionList = true;
              }
            }
          }
        });
      });
      zonesInfoCollection
        .where("environment", "==", this.$store.state.environment.toLowerCase())
        .onSnapshot((snapshot) => {
          const zoneInformations = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          this.$store.dispatch("setZoneInformations", zoneInformations);
        });
      vendorsCollection.orderBy("businessUnit").onSnapshot((snapshot) => {
        const vendorsData = snapshot.docs.map((doc) => ({
          Id: doc.id,
          ...doc.data(),
        }));
        this.$store.dispatch("setVendors", vendorsData);
      });
      configurationsCollection.onSnapshot((snapshot) => {
        const config = snapshot.docs.map((doc) => {
          return {
            Id: doc.id,
            ...doc.data(),
          };
        });
        this.$store.dispatch("setConfigurations", config);
      });
    } catch (error) {
      console.log("error:", error);
    }

    await this.renderFSAToMap("AB");
    const mongoAppForShape = await mongoDbAppForShape();
    const zonesCollection = mongoAppForShape.collection("zones");
    for await (const change of zonesCollection.watch()) {
      switch (change.operationType) {
        case "insert":
        case "update":
        case "replace":
        case "delete": {
          this.$notify({
            group: "zones",
            clean: true,
          });
          await this.renderZonesToMap(false, this.$store.state.region);
        }
      }
    }
  },
};
</script>
<style lang="scss">
@import "https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css";
@import "../assets/theme/color_variables";
/* FSA name layer */
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet-control-geosearch.active form {
  display: flex;
  flex-wrap: wrap;
  min-width: 250px;
}

.leaflet-control-geosearch.active .glass {
  flex: 1;
}

.leaflet-control-geosearch.active .reset {
  border: none;
  background: none;
}

.leaflet-control-geosearch .results {
  width: 100%;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results .leaflet-bar-notfound {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}
/* customizing the circles for FSA name layer */
.marker-cluster > * {
  opacity: 0.2 !important;
  background-color: $success !important;
  color: white !important;
}
.leaflet-popup-content-wrapper {
  width: 53vw;
}
.FSA-Content > .leaflet-popup-content-wrapper {
  width: 8vw;
}
.leaflet-popup-content {
  width: 90% !important;
}
.FSA_tooltip {
  /* background: transparent; */
  border: none;
  font-weight: 600;
  font-style: italic;
}
/* esri search control */
.geocoder-control-input {
  border: 2px solid $success !important;
  font-family: "Poppins", sans-serif !important;
}
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-draw-draw-polygon,
.leaflet-draw-draw-rectangle,
.leaflet-draw-draw-circle,
.leaflet-draw-draw-marker,
.leaflet-draw-section,
.leaflet-control-layers,
.leaflet-control-layers-expanded {
  color: $success !important;
}
.navbar-item {
  color: $success !important;
}
</style>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
.leaflet-control-layers-overlays {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
