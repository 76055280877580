import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const getInitialState = () => ({
  user: null,
  configurations: new Map(),
  access: "",
  region: "AB",
  selectedVehicles: [],
  regionZones: [],
  regions: [],
  sharedViews: [],
  DISTRIBUTIONZONES: [],
  vendors: [],
  zoneInformations: [],
  trucksToBeAssigned: [],
  hubsToBeAssigned: [],
  selectedFSAMode: false,
  environment: "",
  requestBusy: false,
  queue: [] as any,
  vehicles: [],
});

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setUser(state, val) {
      state.user = val;
    },
    setAccess(state, val) {
      state.access = val;
    },
    updateAuthState(state: any, { user, access }) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
      state.access = access;
    },
    setRegion(state, val) {
      state.region = val;
    },
    setSelectedVehicles(state, val) {
      state.selectedVehicles = val;
    },
    setRegionZones(state, val) {
      state.regionZones = val;
    },
    setTrucksToBeAssigned(state, val) {
      state.trucksToBeAssigned = val;
    },
    setHubs(state, val) {
      state.DISTRIBUTIONZONES = val;
    },
    setZoneInformations(state, val) {
      state.zoneInformations = val;
    },
    setHubsToBeAssigned(state, val) {
      state.hubsToBeAssigned = val;
    },
    setSelectedFSAMode(state, val) {
      state.selectedFSAMode = val;
    },
    setEnvironment(state, val) {
      state.environment = val;
    },
    setRegions(state, val) {
      state.regions = val;
    },
    setSharedViews(state, val) {
      state.sharedViews = val;
    },
    setVehicles(state, val) {
      state.vehicles = val;
    },
    setVendors(state, val) {
      state.vendors = val;
    },
    setConfigurations(state, val) {
      val.forEach((config: any) => state.configurations.set(config.Id, config));
    },
    resetValues(state) {
      Object.assign(state, getInitialState());
    },
    onLoad(state) {
      state.user = JSON.parse(localStorage.getItem("user") || "[]") || null;
      state.access = localStorage.getItem("access") || "";
    },
    onRequest(state, val: any) {
      if (state.requestBusy) {
        state.queue.push(val);
      } else {
        state.requestBusy = true;
        val();
      }
    },
  },
  actions: {
    initialize({ commit }) {
      commit("setRegion", "calgary");
    },
    updateUser({ commit }: any, user: any) {
      if (!user) {
        const access = "";
        commit("updateAuthState", { user, access });
      } else if (user && user.getIdTokenResult) {
        user.getIdTokenResult(true).then((idTokenResult: any) => {
          const access =
            idTokenResult && idTokenResult.claims && idTokenResult.claims;
          commit("updateAuthState", { user, access });
        });
      }
    },
    setUser({ commit }, payload) {
      commit("setUser", payload);
    },
    setAccess({ commit }, payload) {
      commit("setAccess", payload);
    },
    setRegion({ commit }, payload) {
      commit("setRegion", payload);
    },
    setSharedViews({ commit }, payload) {
      commit("setSharedViews", payload);
    },
    setSelectedVehicles({ commit }, payload) {
      commit("setSelectedVehicles", payload);
    },
    // setTruckFleetSetting({ commit }, payload) {
    //   commit('setTruckFleetSetting', payload)
    // },
    setRegionZones({ commit }, payload) {
      commit("setRegionZones", payload);
    },
    setTrucksToBeAssigned({ commit }, payload) {
      commit("setTrucksToBeAssigned", payload);
    },
    setHubs({ commit }, payload) {
      commit("setHubs", payload);
    },
    setZoneInformations({ commit }, payload) {
      commit("setZoneInformations", payload);
    },
    setHubsToBeAssigned({ commit }, payload) {
      commit("setHubsToBeAssigned", payload);
    },
    setSelectedFSAMode({ commit }, payload) {
      commit("selectedFSAMode", payload);
    },
    setEnvironment({ commit }, payload) {
      commit("setEnvironment", payload);
    },
    onLoad({ commit }) {
      commit("onLoad");
    },
    onRequest({ commit }, payload) {
      commit("onRequest", payload);
    },
    setRegions({ commit }, payload) {
      commit("setRegions", payload);
    },
    setVehicles({ commit }, payload) {
      commit("setVehicles", payload);
    },
    setVendors({ commit }, payload) {
      commit("setVendors", payload);
    },
    setConfigurations({ commit }, payload) {
      commit("setConfigurations", payload);
    },
  },
  getters: {
    getRegion: (state: any) => state.region,
    DistributionZoneTypes: (state: any) =>
      ((state.configurations.get("DISTRIBUTIONZONES") || {})
        .DistributionZoneType || []).map((item: any, index: number) => {
        return { value: index, label: item };
        }),
    Facility: (state: any) =>
      (state.configurations.get("DISTRIBUTIONZONES") || {}).Facility,
    VehicleType: (state: any) =>
      (state.configurations.get("VEHICLES") || {}).VehicleType,
  },
});
