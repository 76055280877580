var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "section is-small",
      staticStyle: { "max-width": "500px", margin: "0 auto" },
    },
    [
      _c("h3", { staticClass: "title is-5" }, [
        _vm._v("Chose your environment"),
      ]),
      _c(
        "button",
        {
          staticClass: "button is-fullwidth is-success my-1",
          on: {
            click: function ($event) {
              return _vm.changePath("Logistics")
            },
          },
        },
        [_vm._v(" Logistics ")]
      ),
      _c(
        "button",
        {
          staticClass: "button is-fullwidth is-success my-1",
          on: {
            click: function ($event) {
              return _vm.changePath("Installation")
            },
          },
        },
        [_vm._v(" Installations ")]
      ),
      _c(
        "button",
        {
          staticClass: "button is-fullwidth is-success my-1",
          on: {
            click: function ($event) {
              return _vm.changePath("Services")
            },
          },
        },
        [_vm._v(" Services ")]
      ),
      _c(
        "button",
        {
          staticClass: "button is-fullwidth is-yellow my-1",
          on: { click: _vm.logout },
        },
        [_vm._v(" Logout ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }