<template>
  <div>
    <div v-if="!loading">
      <div class="title is-7">
        Saving Shape {{ currentRegion ? `In Region ${currentRegion}` : "" }}
      </div>
      <div class="is-size-7 has-text-weight-semibold has-text-danger">
        {{ msg }}
      </div>
      <div class="is-flex is-flex-direction-row is-align-items-baseline"></div>
      <div class="notification is-white">
        <multiselect
          v-model="zone"
          :options="zoneList"
          label="value"
          :close-on-select="true"
          track-by="value"
        ></multiselect>
      </div>
      <div class="columns notification is-white">
        <div class="column"></div>
        <div class="column has-text-centered">
          <button class="button is-warning is-small" @click="closeModal()">
            Cancel
          </button>
        </div>
        <div class="column">
          <button
            class="button is-small is-success"
            @click="
              () => {
                saveToFirestore();
              }
            "
          >
            Save
          </button>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
      "
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import store from "../store";
import EventHandler from "../EventHandler";

export default {
  components: {
    PulseLoader,
    Multiselect,
  },
  data() {
    return {
      msg: "",
      zone: {
        value: "",
        zoneId: "",
      },
      loading: false,
    };
  },
  props: {
    coordinates: {
      type: Array,
    },
    region: {
      type: String,
    },
  },
  computed: {
    zoneList() {
      const currentRegion = store.state.region.trim();
      return store.state.zoneInformations;
    },
    currentRegion() {
      const currentRegionSelected = store.state.region;
      const selectedRegion = store.state.regions.find(
        (region) => region.id === currentRegionSelected
      );
      return selectedRegion.name;
    },
  },
  methods: {
    closeModal() {
      EventHandler.$emit("closeModal");
      this.close();
    },
    async saveToFirestore() {
      this.loading = true;
      const zone = this.zone.value ? this.zone.value.trim() : "";
      const zoneId = this.zone.id ? this.zone.id.trim() : "";
      const coordinates = this.coordinates;
      if (zone && zoneId) {
        try {
          this.loading = false;
          EventHandler.$emit("closeModal");
          this.close();
          this.msg = "";
          await this.updateMap({
            coordinates,
            zoneId,
          });
        } catch (err) {
          this.loading = false;
          this.msg = "Error adding";
        }
      } else {
        this.loading = false;
        this.msg = "Please select name";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
  background: #e93434;
  color: #ffffff;
}
</style>
