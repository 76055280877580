import Vue from 'vue'
import EditMarkerZoneModal from '../../components/EditMarkerZoneModal.vue'

export default function EditMarker (
  store: any,
  map: any,
  DistributionZone: any,
  editable: boolean,
  coordinates: any
) {
  const ComponentClass = Vue.extend(EditMarkerZoneModal)
  const instance = new ComponentClass({
    propsData: {
      store: store,
      map: map,
      coordinates: coordinates,
      DistributionZone,
      editable
    },
    methods: {
      close: () => {
        map.closePopup()
      }
    }
  })

  return instance
}
