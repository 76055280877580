<template>
  <section
    style="max-width: 500px; margin: 0 auto"
    class="container is-desktop"
  >
    <LoginMessageModal
      v-if="showLoginMessageModal"
      @close-modal="showLoginMessageModal = false"
      @first-time-login="(bool) => (firstTimeLogin = bool)"
    />
    <div
      v-if="!sent2FA && !needsEnrollment && !authenticating2FA"
      style="position:absolute top:10rem left: 15% width:70%"
    >
      <section v-if="!user" id="firebaseui-auth-container" class="notification">
        <form style="max-width : 500px margin: 0 auto">
          <div class="field">
            <input
              class="input"
              type="text"
              placeholder="Email"
              autocomplete=""
              v-model="email"
            />
          </div>
          <div class="field container">
            <ul>
              <li
                class="is-size-7 has-text-weight-semibold"
                v-bind:class="{ is_valid: containsEightCharacters }"
              >
                <!-- {{ $t('login.rules.characters') }} -->
                8 Characters
              </li>
              <li
                class="is-size-7 has-text-weight-semibold"
                v-bind:class="{ is_valid: containsNumber }"
              >
                <!-- {{ $t('login.rules.number') }} -->
                Contains Number
              </li>
              <li
                class="is-size-7 has-text-weight-semibold"
                v-bind:class="{ is_valid: containsUppercase }"
              >
                <!-- {{ $t('login.rules.uppercase') }} -->
                Contains Uppercase
              </li>
              <li
                class="is-size-7 has-text-weight-semibold"
                v-bind:class="{ is_valid: containsSpecialCharacter }"
              >
                <!-- {{ $t('login.rules.specialCharacters') }} -->
                Contains Special Character
              </li>
            </ul>
            <div
              class="checkmark_container"
              v-bind:class="{ show_checkmark: validPassword }"
            >
              <svg width="50%" height="50%" viewBox="0 0 140 100">
                <path
                  class="checkmark"
                  v-bind:class="{ checked: validPassword }"
                  d="M10,50 l25,40 l95,-70"
                />
              </svg>
            </div>
            <input
              class="input"
              id="password"
              type="password"
              autocomplete=""
              placeholder="Password"
              @input="checkPassword"
              v-model="pass"
            />
          </div>
          <div class="recaptchaContainer container is-fluid"></div>
          <div>
            <button
              v-show="!firstTimeLogin"
              id="sign-in"
              v-bind:disabled="!validPassword"
              class="button field is-success is-fullwidth"
              v-bind:class="{ 'is-loading': isLoading }"
              @click.stop.prevent="logIn"
            >
              <!-- {{ $t('login.button.login') }} -->
              Login
            </button>
            <button
              v-show="firstTimeLogin"
              id="sign-up"
              v-bind:disabled="!validPassword"
              class="button field is-primary is-fullwidth"
              v-bind:class="{ 'is-loading': isLoading }"
              @click.stop.prevent="signUp"
            >
              <!-- {{ $t('login.button.signup') }} -->
              Sign Up
            </button>
            <a
              v-show="firstTimeLogin"
              class="is-size-7 button is-small"
              href="javascript:void(0)"
              @click="firstTimeLogin = false"
            >
              <!-- {{ $t('login.messages.goBackToLogin') }} -->
              Go to login
            </a>
            <a
              v-show="!firstTimeLogin"
              class="is-size-7 button is-small"
              href="javascript:void(0)"
              @click="firstTimeLogin = true"
            >
              <!-- {{ $t('login.messages.goBackToSignup') }} -->
              Go to sign-up
            </a>
          </div>
        </form>
      </section>
    </div>
    <div class="container is-fullwidth">
      <div v-if="sent2FA" class="notification">
        <label class="is-size-7 tag is-warning field">
          <!-- {{ $t('login.messages.verification') }} -->
          We just sent you a verification code.
        </label>
        <input
          class="input field"
          type="number"
          placeholder="Please enter your verification code here."
          v-model="verificationCode"
        />
        <button
          class="button is-fullwidth is-primary"
          v-bind:class="{ 'is-loading': isLoading }"
          @click="verifyCode"
        >
          <!-- {{ $t('login.button.submit') }} -->
          Submit
        </button>
        <label class="is-size-7 tag is-light is-info">
          <!-- {{ $t('login.messages.standardRate') }} -->
          Standard rates may apply.
        </label>
      </div>
      <div v-if="needsEnrollment" class="notification">
        <div style="padding: 8px">
          <strong>
            <!-- {{ $t('login.twofa.secureAccess') }} -->
            Secure Portal
          </strong>
        </div>
        <input
          v-if="getIsBestBuy"
          class="input field"
          type="tel"
          placeholder="Phone number..."
          :value="last2Digits"
        />
        <input
          v-if="!getIsBestBuy"
          class="input field"
          type="tel"
          placeholder="Phone number..."
          v-model="authPhoneNumber"
        />
        <input
          v-if="registerVerificationId"
          class="input field"
          type="number"
          placeholder="Please enter your verification code."
          v-model="registrationVerificationCode"
        />
        <button
          v-if="registerVerificationId"
          class="button is-fullwidth is-primary"
          v-bind:class="{ 'is-loading': isLoading }"
          @click="verifyCodeForEnrollment"
        >
          Submit
        </button>
      </div>
    </div>
    <div style="margin-top: 100px" v-if="!needsEnrollment">
      <div class="field" v-if="user">
        <!-- {{ $t('login.messages.redirectingToPortal') }} -->
        Hang tight, we are redirecting you to the portal.
      </div>
      <md-progress-spinner
        v-if="user"
        :md-diameter="50"
        :md-stroke="2"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div v-if="!sent2FA && captchaExpired" class="notification is-warning">
      <button class="delete" @click="captchaExpired = false"></button>
      <!-- {{ $t('login.messages.captchaExpired') }} -->
      Session expired. Please attempt again.
    </div>
    <md-snackbar
      id="snackbar"
      :md-position="position"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="update">OK</md-button>
    </md-snackbar>
    <div class="recaptchaContainer2 container is-fluid"></div>
  </section>
</template>
<script>
import { auth } from "@/store/fireStore";
import axios from "axios";
export default {
  name: "Login",
  components: {
    LoginMessageModal: () => import("../components/LoginMessageModal"),
  },
  data() {
    return {
      client: process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID,
      appVerifier: null,
      prefLang: "en",
      showSnackbar: false,
      position: "center",
      duration: 6000,
      isInfinity: true,
      message: "",
      user: null,
      type: "",
      email: "",
      pass: "",
      verificationCode: "",
      registrationVerificationCode: "",
      factorName: "",
      resolver: null,
      selected2FAIndex: 0,
      sent2FA: false,
      registered2FA: false,
      captchaExpired: false,
      verificationId: "",
      registerVerificationId: "",
      enrolledFactors: null,
      isLoading: false,
      needsEnrollment: false,
      authenticating2FA: false,
      authPhoneNumber: "",
      passwordLength: 0,
      containsEightCharacters: false,
      containsNumber: false,
      containsUppercase: false,
      containsSpecialCharacter: false,
      validPassword: false,
      initRecaptcha: false,
      firstTimeLogin: false,
      showLoginMessageModal: false,
    };
  },
  mounted() {
    // this.prefLang = localStorage.getItem('prefLang') || 'en'
    // this.$i18n.locale = this.prefLang
    // auth.languageCode = this.prefLang
    const user = localStorage.getItem("user");
    // const environment = localStorage.getItem("environment");
    // // const
    // console.log('currently login user:', user)
    // console.log('currently environment:', environment)
    if (user) {
      this.$router.push(`/home`);
    }
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.user = null;
        this.showLoginMessageModal = true;
      } else {
        console.log('user :>> ', user.uid);
        this.showLoginMessageModal = false;
        await this.$store.dispatch("updateUser", user);
        user.getIdTokenResult(true).then(async (idTokenResult) => {
          const email =
            idTokenResult && idTokenResult.claims && idTokenResult.claims.email;
          const idToken = await auth.currentUser.getIdToken(true);
          await axios({
            url: process.env.VUE_APP_VALIDATE_EMAIL,
            method: "POST",
            data: {
              email: email,
              token: idToken,
              customerId: process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID,
              component: 'Mapping'
            },
          }).then(async (s) => {
            console.log("s:", s);
            if (s.data && s.data.validAccess === "VALID_EMAIL") {
              this.$store.dispatch(
                "updateUser",
                auth.currentUser && auth.currentUser
              );
              setTimeout(() => {
                this.isLoading = false;
                this.$router.push(`/home`);
              }, 500);
            } else {
              this.handleUIError("Invalid Account");
            }
          });
        });
      }
    });
  },
  computed: {
    last2Digits() {
      return this.authPhoneNumber
        ? `*******${String(this.authPhoneNumber).slice(8)}`
        : null;
    },
  },
  methods: {
    checkPassword() {
      this.passwordLength = this.pass.length;
      const format = /[ !@#$%^&*()_+\-=[\]{}':"\\|,.<>/?]/;
      if (this.passwordLength >= 8) {
        this.containsEightCharacters = true;
      } else {
        this.containsEightCharacters = false;
      }
      this.containsNumber = /\d/.test(this.pass);
      this.containsUppercase = /[A-Z]/.test(this.pass);
      this.containsSpecialCharacter = format.test(this.pass);
      if (
        this.containsEightCharacters === true &&
        this.containsSpecialCharacter === true &&
        this.containsUppercase === true &&
        this.containsNumber === true
      ) {
        this.validPassword = true;
        if (!this.initRecaptcha) {
          this.initRecaptcha = true;
        }
      } else {
        this.validPassword = false;
        this.initRecaptcha = false;
      }
    },
    async signUp() {
      this.isLoading = true;
      try {
        await auth.createUserWithEmailAndPassword(this.email, this.pass);
      } catch (error) {
        const msg = {
          "auth/wrong-password":
            "The password is invalid or the user does not have a password.",
          "auth/user-not-found":
            "There is no user record corresponding to this identifier. The user may have been deleted.",
          "auth/invalid-email": "The email address is badly formatted.",
        };
        this.handleUIError(`${msg[error.code] || error.message}`);
      }
    },
    async logIn() {
      if (!this.validPassword) return;
      this.isLoading = true;
      try {
        await auth.signInWithEmailAndPassword(this.email, this.pass).then(() => {
          window.location = "/home";
        });
      } catch (error) {
        this.isLoading = false;
        this.handleUIError(error.message);
      }
    },
    // authenticate 2nd auth on login
    handleUIError(msg) {
      this.isLoading = false;
      this.message = msg || "ERROR!";
      this.showSnackbar = true;
      this.logout();
    },
    update() {
      window.location = "/";
    },
    logout() {
      auth.signOut();
      this.registerVerificationId = false;
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("prefLang", lang);
      auth.languageCode = localStorage.getItem("prefLang") || "en";
    },
  },
};
</script>
<style lang="scss">
@import "../assets/theme/color_variables";
.is_valid {
  color: $green;
}
.is_valid:before {
  width: 100%;
}
.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 80%;
  background: $green;
  width: 30px;
  height: 30px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}
.show_checkmark {
  visibility: visible;
  opacity: 1;
}
.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}
.checked {
  animation: draw 0.5s ease forwards;
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
