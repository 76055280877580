// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.push([module.id, "@import url(https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css);"]);
// Module
exports.push([module.id, "/* variables */\n/* main colors */\n/* bright colors */\n/*  light 1 colors*/\n/* light 2 colors */\n/* dark colors */\n/* override bulma colors */\n/* Text Color */\n/* Form Elements */\n/* UI Text */\n/* Background Color */\n/* FSA name layer */\n.leaflet-control-geosearch.active form {\n  display: flex;\n  flex-wrap: wrap;\n  min-width: 250px;\n}\n.leaflet-control-geosearch.active .glass {\n  flex: 1;\n}\n.leaflet-control-geosearch.active .reset {\n  border: none;\n  background: none;\n}\n.leaflet-control-geosearch .results {\n  width: 100%;\n}\n.leaflet-control-geosearch .results.active {\n  padding: 8px 0;\n  border-top: 1px solid #c6c6c6;\n}\n.leaflet-control-geosearch .results .leaflet-bar-notfound {\n  padding: 8px 0;\n  border-top: 1px solid #c6c6c6;\n}\n\n/* customizing the circles for FSA name layer */\n.marker-cluster > * {\n  opacity: 0.2 !important;\n  background-color: #000000 !important;\n  color: white !important;\n}\n.leaflet-popup-content-wrapper {\n  width: 53vw;\n}\n.FSA-Content > .leaflet-popup-content-wrapper {\n  width: 8vw;\n}\n.leaflet-popup-content {\n  width: 90% !important;\n}\n.FSA_tooltip {\n  /* background: transparent; */\n  border: none;\n  font-weight: 600;\n  font-style: italic;\n}\n\n/* esri search control */\n.geocoder-control-input {\n  border: 2px solid #000000 !important;\n  font-family: \"Poppins\", sans-serif !important;\n}\n.leaflet-control-zoom-in,\n.leaflet-control-zoom-out,\n.leaflet-draw-draw-polygon,\n.leaflet-draw-draw-rectangle,\n.leaflet-draw-draw-circle,\n.leaflet-draw-draw-marker,\n.leaflet-draw-section,\n.leaflet-control-layers,\n.leaflet-control-layers-expanded {\n  color: #000000 !important;\n}\n.navbar-item {\n  color: #000000 !important;\n}", ""]);
// Exports
module.exports = exports;
