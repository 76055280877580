import Vue from 'vue'
import EditShapeModal from '../../components/EditShapeModal.vue'

export default function EditShape (
  store: any,
  coordinates: any,
  shape: any,
  vehicles: any,
  map: any,
  editable: boolean,
  addMoreShapeFunc: (a: boolean) => {},
  renderMapFunc: (gettingZone: boolean, region: string) => {},
  selectedFSAModeEdit: () => {},
  closeEditCurrentShapeMode: () => {}
) {
  const ComponentClass = Vue.extend(EditShapeModal)
  const instance = new ComponentClass({
    propsData: {
      store,
      coordinates,
      shape,
      vehicles,
      editable
    },
    methods: {
      close: () => {
        closeEditCurrentShapeMode()
        map.closePopup()
      },
      addMoreShapeFunc: (toAdd: boolean) => {
        addMoreShapeFunc(toAdd)
      },
      renderMapFunc: async () => {
        setTimeout(async () => {
          await renderMapFunc(true, store.state.region)
        }, 1000)
      },
      selectedFSAModeEdit: async () => {
        await selectedFSAModeEdit()
      }
    }
  })

  return instance
}
