import Vue from 'vue'

import './assets/theme/main_theme.scss'
import './assets/theme/main_layout.scss'

// eslint-disable-next-line
// @ts-ignore
import VueMaterial from 'vue-material'
// eslint-disable-next-line
// @ts-ignore
import VueMoment from 'vue-moment'
import Notifications from 'vue-notification'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import VueTour from 'vue-tour'

import App from './App.vue'
import router from './router'
import store from './store'

require('vue-tour/dist/vue-tour.css')

Vue.config.productionTip = true

Vue.use(VueMaterial)
Vue.use(VueMoment)
Vue.use(Notifications)

Vue.use(VueTour)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
